import useSWR, { SWRConfiguration, SWRResponse, mutate } from "swr";
import { useAuth, useOrganization } from "@clerk/clerk-react";

// Get base URL from environment variable
const API_URL = import.meta.env.VITE_API_URL || "http://localhost:3004";

console.log("API_URL: ", API_URL);

export interface RequestConfig extends RequestInit {
	params?: Record<string, string>;
	query?: Record<string, string>;
}

export type PutFunction = (url: string, data?: Record<string, unknown>, config?: RequestConfig) => Promise<unknown>;

interface RequestError extends Error {
	status?: number;
	data?: unknown;
}

async function createError(res: Response): Promise<RequestError> {
	let data;
	try {
		data = await res.json();
	} catch {
		data = await res.text();
	}

	const error: RequestError = new Error(res.statusText || "An error occurred");
	error.status = res.status;
	error.data = data;
	return error;
}

function buildUrl(path: string, config?: RequestConfig): string {
	const cleanPath = path.startsWith("/") ? path.slice(1) : path;
	const baseUrl = `${API_URL}/${cleanPath}`;
	const url = new URL(baseUrl);

	if (config?.query) {
		Object.entries(config.query).forEach(([key, value]) => {
			if (value !== undefined && value !== null) {
				url.searchParams.append(key, String(value));
			}
		});
	}

	let finalUrl = url.toString();
	if (config?.params) {
		Object.entries(config.params).forEach(([key, value]) => {
			if (value !== undefined && value !== null) {
				finalUrl = finalUrl.replace(`:${key}`, String(value));
			}
		});
	}

	return finalUrl;
}

export function useFetch(): <T>(url: string, config?: RequestConfig) => Promise<T> {
	const { getToken } = useAuth();
	const { organization } = useOrganization();
	const organization_id = organization?.id;

	return async function authedFetch<T>(url: string, config?: RequestConfig): Promise<T> {
		if (!organization_id) throw new Error("Organization not found");

		const token = await getToken();
		if (!token) throw new Error("No authentication token found");

		// Create headers with the three required values
		const headers = new Headers({
			"Content-Type": "application/json",
			"organization-id": organization_id,
			"Authorization": `Bearer ${token}`,
			...config?.headers, // Allow additional headers from config to override defaults if needed
		});

		const finalUrl = buildUrl(url, config);
		const res = await fetch(finalUrl, {
			...config,
			headers,
			body: config?.body && typeof config.body === "object" ? JSON.stringify(config.body) : config?.body,
		});

		if (!res.ok) throw await createError(res);

		const contentType = res.headers.get("content-type");
		if (contentType?.includes("application/json")) {
			return res.json();
		}
		return res.text() as unknown as T;
	};
}

interface UseQueryOptions extends SWRConfiguration {
	config?: RequestConfig;
}

export function useQuery<T>(url: string | null, options?: UseQueryOptions): SWRResponse<T, RequestError> {
	const fetcher = useFetch();
	return useSWR<T, RequestError>(url, url => fetcher<T>(url, options?.config), options);
}

export async function mutateRequest<T>(url: string, config?: RequestConfig): Promise<T> {
	const fetcher = useFetch();
	const data = await fetcher<T>(url, config);
	await mutate(url);
	return data;
}

export function usePost<T>(): (
	url: string,
	data?: Record<string, unknown> | Record<string, unknown>[],
	config?: RequestConfig,
) => Promise<T> {
	const fetcher = useFetch();
	return (url: string, data?: Record<string, unknown> | Record<string, unknown>[], config?: RequestConfig) =>
		fetcher<T>(url, {
			...config,
			method: "POST",
			body: data ? JSON.stringify(data) : undefined,
		});
}

export function usePut<T>(): (url: string, data?: Record<string, unknown>, config?: RequestConfig) => Promise<T> {
	const fetcher = useFetch();
	return (url: string, data?: Record<string, unknown>, config?: RequestConfig) =>
		fetcher<T>(url, {
			...config,
			method: "PUT",
			body: data ? JSON.stringify(data) : undefined,
		});
}

export function useDelete<T>(): (url: string, config?: RequestConfig) => Promise<T> {
	const fetcher = useFetch();
	return (url: string, config?: RequestConfig) => fetcher<T>(url, { ...config, method: "DELETE" });
}

export type QueryResponse<T> = SWRResponse<T, RequestError>;
