import { useCallback, useMemo, useState } from "react";
import { Key, mutate as mutateSWR, MutatorOptions } from "swr";
import useSWRMutation, { SWRMutationConfiguration, SWRMutationResponse, TriggerWithArgs } from "swr/mutation";

import { FetchKey, FetchResource, INTERNAL_getFetchKey, INTERNAL_invalidateResource } from "./useFetch";

export async function revalidate<
	TResourceData,
	TResourceParams extends Record<string, unknown> | undefined = undefined,
>(resource: FetchResource<TResourceParams>): Promise<TResourceData | undefined> {
	const fetchKey = INTERNAL_getFetchKey(resource);
	INTERNAL_invalidateResource(fetchKey);
	return mutateSWR<TResourceData>(fetchKey);
}

// Revalidates all resources that have the provided uri.
export async function revalidateResourcesMatchingUri<
	TResourceData,
	TResourceParams extends Record<string, unknown> = Record<string, unknown>,
>(uri: string): Promise<TResourceData | undefined> {
	return mutateSWR<TResourceData>((key: FetchKey<TResourceParams>) => Array.isArray(key) && key[0] === uri, undefined, {
		revalidate: true,
	});
}

type RevalidateOnSuccess<TMutationResponse, TResourceParams extends Record<string, unknown> | undefined> =
	| ((data: TMutationResponse | undefined) => FetchResource<TResourceParams> | FetchResource<TResourceParams>[])
	| FetchResource<TResourceParams>
	| FetchResource<TResourceParams>[];

type MutateArgs<TMutationResponse, TResourceData, TResourceParams extends Record<string, unknown> | undefined> = Omit<
	MutatorOptions<TResourceData>,
	"populateCache" | "revalidate"
> & {
	resource: FetchResource<TResourceParams>;
	revalidateOnSuccess?: RevalidateOnSuccess<TMutationResponse, Record<string, unknown> | undefined>;
	update: (current: TResourceData | undefined) => Promise<TMutationResponse>;
	populateCache?: (data: TMutationResponse, current: TResourceData | undefined) => TResourceData;
	optimisticSuccess?: () => () => void;
	onSuccess?: (data: TMutationResponse | undefined) => void;
	onError?: (error: unknown) => void;
};

// A wrapper around swr's global `mutate` with some predetermined defaults, and
// a more constrained api for our usage.
// See the `mutate` docs here: https://swr.vercel.app/docs/mutation#mutate
export async function mutate<
	TMutationResponse,
	TResourceData,
	TResourceParams extends Record<string, unknown> | undefined = undefined,
>({
	resource,
	revalidateOnSuccess,
	update,
	optimisticSuccess,
	onSuccess,
	onError,
	...opts
}: MutateArgs<TMutationResponse, TResourceData, TResourceParams>): Promise<TMutationResponse | undefined> {
	const revalidateKey = INTERNAL_getFetchKey(resource);

	let rollbackOptimisticSuccess;
	try {
		rollbackOptimisticSuccess = optimisticSuccess?.();

		// @ts-expect-error TS(2322): Type '(TMutationResponse | undefined)[]' is not as... Remove this comment to see the full error message
		const data: TMutationResponse | undefined = await mutateSWR<TResourceData, TMutationResponse>(
			revalidateKey,
			update,
			{
				...opts,
				populateCache: opts?.populateCache ?? false,
				revalidate: opts?.populateCache == null,
			},
		);
		revalidateResources(typeof revalidateOnSuccess === "function" ? revalidateOnSuccess(data) : revalidateOnSuccess);
		rollbackOptimisticSuccess?.();
		onSuccess?.(data);
		return data;
	} catch (err) {
		rollbackOptimisticSuccess?.();
		onError?.(err);
		throw err;
	}
}

export type UseMutationArgs<
	TMutationResponse,
	TMutationArgs,
	TResourceData,
	TResourceParams extends Record<string, unknown> | undefined,
> = Omit<
	SWRMutationConfiguration<TMutationResponse, unknown, Key, TMutationArgs, TResourceData>,
	"populateCache" | "revalidate" | "onSuccess" | "onError"
> & {
	shouldThrowOnError?: boolean;
	resource?: FetchResource<TResourceParams>;
	revalidateOnSuccess?: RevalidateOnSuccess<TMutationResponse, Record<string, unknown> | undefined>;
	populateCache?: (data: TMutationResponse, current: TResourceData | undefined) => TResourceData;
	optimisticSuccess?: (args: TMutationArgs) => () => void;
	onSuccess?: (data: TMutationResponse, key: string, args: TMutationArgs) => void;
	onError?: (err: unknown, key: string, args: TMutationArgs) => void;
	update: (args: {
		resource: FetchResource<TResourceParams> | null;
		input: TMutationArgs;
	}) => Promise<TMutationResponse>;
};

export type UseMutationReturnType<
	TMutationResponse,
	TMutationArgs,
	TResourceData,
	TResourceParams extends Record<string, unknown> | undefined,
> = {
	data: TMutationResponse | undefined;
	isMutating: boolean;
	error: SWRMutationResponse<TMutationResponse, unknown, Key, TMutationArgs>["error"];
	mutate: (
		args: TMutationArgs,
		opts?: Omit<
			SWRMutationConfiguration<TMutationResponse, unknown, Key, TMutationArgs, TResourceData>,
			"populateCache" | "revalidate" | "onSuccess" | "onError"
		> & {
			revalidateOnSuccess?: RevalidateOnSuccess<TMutationResponse, TResourceParams>;
			onSuccess?: (data: TMutationResponse, key: string, args: TMutationArgs) => void;
			onError?: (err: unknown, key: string, args: TMutationArgs) => void;
			populateCache?: (data: TMutationResponse, current: TResourceData | undefined) => TResourceData;
			optimisticSuccess?: (args: TMutationArgs) => () => void;
		},
	) => Promise<TMutationResponse>;
	reset: SWRMutationResponse<TMutationResponse, unknown, Key, TMutationArgs>["reset"];
};

// A wrapper around `uswSWRMutation` with some predetermined defaults, and
// a more constrained api for our usage.
// See the `useSWRMutation` docs here: https://swr.vercel.app/docs/mutation#useswrmutation
export function useMutation<
	TMutationResponse,
	TMutationArgs,
	TResourceData,
	TResourceParams extends Record<string, unknown> | undefined = undefined,
>({
	resource,
	revalidateOnSuccess: topLevelRevalidateOnSuccess,
	optimisticSuccess: topLevelOptimisticSuccess,
	update: updateFn,
	onSuccess: topLevelOnSuccess,
	onError: topLevelOnError,
	shouldThrowOnError,
	populateCache: topLevelPopulateCache,
	...opts
}: UseMutationArgs<TMutationResponse, TMutationArgs, TResourceData, TResourceParams>): UseMutationReturnType<
	TMutationResponse,
	TMutationArgs,
	TResourceData,
	TResourceParams
> {
	const randomKey = useState(() => Math.random())[0];

	const update = useMemo(
		() =>
			(key: Key, { arg }: Readonly<{ arg: TMutationArgs }>): Promise<TMutationResponse> => {
				let fetchKey;
				if (Array.isArray(key)) {
					fetchKey = key as FetchKey<TResourceParams>;
				} else {
					fetchKey = null;
				}
				return updateFn({
					resource: fetchKey
						? ({ uri: fetchKey[0], params: fetchKey[1] } as unknown as FetchResource<TResourceParams>)
						: null,
					input: arg,
				});
			},
		[updateFn],
	);

	// The revalidate key passed to `useSWRMutation` is important because:
	// - The resource for that key will be invalidated and potentially refeteched, and
	//   swr will dedupe fetches with other `useSWR` calls for that resource and avoid
	//   race conditions (https://swr.vercel.app/docs/mutation#avoid-race-conditions)
	// - unknown options like `optimisticData` will be associated with the type/shape of
	//   that cached resource.
	// - If other resources need to be invalidated, the `revalidateOnSuccess` option
	//   should be used.
	const revalidateKey = useMemo(() => {
		if (!resource) {
			// useSWRMutation doesn't support not providing a key that's bound to the hook.
			// So when a resource to revalidate isn't passed, we use a random key,
			// and revalidate other resources only onSuccess if desired.
			// See https://github.com/vercel/swr/discussions/2461#discussioncomment-5281784
			return `${randomKey}`;
		} else {
			const uri = resource.uri;
			const params = resource.params;
			return [uri, params];
		}
	}, [randomKey, resource]);

	const { data, error, isMutating, trigger, reset } = useSWRMutation<
		TMutationResponse,
		unknown,
		Key,
		TMutationArgs,
		TResourceData
	>(revalidateKey, update, {
		...opts,
		// that it's in the documentation
		// @ts-expect-error TS(2769): No overload matches this call.
		throwOnError: shouldThrowOnError ?? true,
		populateCache: topLevelPopulateCache ?? false,
		revalidate: topLevelPopulateCache == null,
	});

	const boundMutate: UseMutationReturnType<TMutationResponse, TMutationArgs, TResourceData, TResourceParams>["mutate"] =
		useCallback(
			async (args, opts): Promise<TMutationResponse> => {
				const {
					onSuccess: innerOnSuccess,
					revalidateOnSuccess: innerRevalidateOnSuccess,
					optimisticSuccess: innerOptimisticSuccess,
					onError: innerOnError,
					populateCache: innerPopulateCache,
					...swrOpts
				} = opts ?? {};
				const populateCache = innerPopulateCache ?? topLevelPopulateCache;
				const onSuccess = innerOnSuccess ?? topLevelOnSuccess;
				const revalidateOnSuccess = innerRevalidateOnSuccess ?? topLevelRevalidateOnSuccess;
				const optimisticSuccess = innerOptimisticSuccess ?? topLevelOptimisticSuccess;

				const rollbackOptimisticSuccess = optimisticSuccess?.(args);
				const onError = innerOnError ?? topLevelOnError;
				return (trigger as TriggerWithArgs<TMutationResponse, unknown, Key, TMutationArgs>)(args, {
					...swrOpts,
					populateCache: populateCache ?? false,
					revalidate: populateCache == null,
					onSuccess: (data, key) => {
						revalidateResources(
							typeof revalidateOnSuccess === "function" ? revalidateOnSuccess(data) : revalidateOnSuccess,
						);
						rollbackOptimisticSuccess?.();
						onSuccess?.(data, key, args);
					},
					onError: (err, key) => {
						rollbackOptimisticSuccess?.();
						onError?.(err, key, args);
					},
				});
			},
			[
				topLevelOnError,
				topLevelOnSuccess,
				topLevelOptimisticSuccess,
				topLevelPopulateCache,
				topLevelRevalidateOnSuccess,
				trigger,
			],
		);
	return { data, error, isMutating, mutate: boundMutate, reset };
}

function revalidateResources<TResourceParams extends Record<string, unknown> | undefined>(
	resources: FetchResource<TResourceParams> | FetchResource<TResourceParams>[] | undefined,
) {
	if (!resources) {
		return;
	}
	if (Array.isArray(resources)) {
		resources.forEach(({ uri, params }) => revalidate({ uri, params }));
	} else {
		revalidate(resources);
	}
}

export type LocalMutateArgs<TResourceData, TResourceParams extends Record<string, unknown> | undefined> = {
	resource: FetchResource<TResourceParams>;
	update: TResourceData | ((current: TResourceData | undefined) => TResourceData | undefined);
};

// A wrapper around swr's global `mutate` with some predetermined defaults
// for locally mutating resources in the swr cache
// See the `mutate` docs here: https://swr.vercel.app/docs/mutation#mutate
export async function localMutate<
	TResourceData,
	TResourceParams extends Record<string, unknown> | undefined = undefined,
>({ resource, update }: LocalMutateArgs<TResourceData, TResourceParams>): Promise<TResourceData> {
	const resourceKey = INTERNAL_getFetchKey(resource);

	// @ts-expect-error TS(2552): Cannot find name 'TMutationResponse'. Did you mean... Remove this comment to see the full error message

	const data: TMutationResponse | undefined = await mutateSWR<TResourceData>(resourceKey, update, {
		populateCache: true,
		revalidate: false,
	});
	return data;
}
