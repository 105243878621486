import { InboxTabs } from "@/app/enums/inbox.enum";
import { InboxHeader } from "@/components";
import InboxList from "@/components/InboxList";
import { useFetch } from "@/hooks/useQuery";
import { useGlobalFilterStore, useInboxStore } from "@/store";
import { ThreadOfEmails } from "@/types/inbox";
import Fuse from "fuse.js";
import { useCallback, useEffect, useMemo, useState } from "react";

const Inbox = () => {
	const { primary, sent, done, syncWithLatest, threadsLoading } = useInboxStore();
	const { searchText, setSearchText } = useGlobalFilterStore();
	const [sortOrder, setSortOrder] = useState<string>("Newest");
	const [activeTab, setActiveTab] = useState(InboxTabs.PRIMARY);
	const [threads, setThreads] = useState<ThreadOfEmails[]>([]);
	const [filteredThreads, setFilteredThreads] = useState<ThreadOfEmails[]>([]);
	const [isModalOpen, setIsModalOpen] = useState(false);

	const fetch = useFetch();

	const fetcher = useCallback(() => {
		// build the url, optionally include the before timestamp and after timestamp
		let list: ThreadOfEmails[];
		switch (activeTab) {
			case InboxTabs.PRIMARY:
				list = primary;
				break;
			case InboxTabs.SENT:
				list = sent;
				break;
			case InboxTabs.DONE:
				list = done;
				break;
		}
		const after = list[0]?.last_message_at;
		const before = list[list.length - 1]?.last_message_at;

		// Format timestamps to ensure proper URL encoding
		const formattedBefore = before ? new Date(before).toISOString() : "";
		const formattedAfter = after ? new Date(after).toISOString() : "";

		return fetch<ThreadOfEmails[]>(
			`/emails/threads/for/${activeTab}?limit=50&before=${formattedBefore}&after=${formattedAfter}`,
		);
	}, [fetch, activeTab, primary, sent, done]);

	// Make a reoccuring call to the API to get the latest threads data every 100 seconds
	useEffect(() => {
		const interval = setInterval(() => {
			syncWithLatest(fetcher);
		}, 10000);
		return () => clearInterval(interval);
	}, [fetcher, syncWithLatest]);

	const currentEmailFuse = useMemo(
		() =>
			new Fuse(threads, {
				keys: ["to.name", "to.address", "campaign.name", "thread.content", "subject"],
			}),
		[threads],
	);

	useEffect(() => {
		const currentThreads = activeTab === InboxTabs.PRIMARY ? primary : activeTab === InboxTabs.SENT ? sent : done;

		setThreads(currentThreads);
		setFilteredThreads(currentThreads);
	}, [primary, sent, done, activeTab]);

	useEffect(() => {
		if (searchText.length === 0) {
			setFilteredThreads(threads);
			return;
		}
		const sortedThreads = [...threads];
		if (sortedThreads.length === 0) return;

		const filteredAndSortedEmails = currentEmailFuse.search(searchText).map(t => t.item);
		if (sortOrder === "Newest") {
			filteredAndSortedEmails.sort(
				(a, b) =>
					(b.last_message_at ? new Date(b.last_message_at).getTime() : 0) -
					(a.last_message_at ? new Date(a.last_message_at).getTime() : 0),
			);
		} else {
			filteredAndSortedEmails.sort(
				(a, b) =>
					(a.last_message_at ? new Date(a.last_message_at).getTime() : 0) -
					(b.last_message_at ? new Date(b.last_message_at).getTime() : 0),
			);
		}

		setFilteredThreads(filteredAndSortedEmails);
	}, [threads, searchText, sortOrder]);

	return (
		<div className="flex h-full w-full flex-col">
			<InboxHeader
				activeTab={activeTab}
				setActiveTab={setActiveTab}
				primaryCount={primary.length}
				searchTerm={searchText}
				setSearchTerm={setSearchText}
				sortOrder={sortOrder}
				setSortOrder={setSortOrder}
				isModalOpen={isModalOpen}
			/>
			<InboxList threads={filteredThreads} onModalStateChange={setIsModalOpen} isLoading={threadsLoading} />
		</div>
	);
};

export default Inbox;
