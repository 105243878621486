import "./globals.css";

import { lazy, Suspense } from "react";
import { ClerkProvider, SignedIn, SignedOut } from "@clerk/clerk-react";
import { Toaster } from "sonner";
import { App } from "./_app";
import { Redirect, Route, Router } from "wouter";

const SignInPage = lazy(() => import("./sign-in/page"));

export default function RootLayout({ children }: { children: React.ReactNode }) {
	return (
		<ClerkProvider publishableKey={import.meta.env.VITE_CLERK_PUBLISHABLE_KEY}>
			<div style={{ fontFamily: "var(--font-geist)" }}>
				<SignedIn>
					<App>{children}</App>
				</SignedIn>
				<SignedOut>
					<Router>
						<Route
							path="/sign-in"
							component={() => {
								if (import.meta.env.VITE_ENV === "production") {
									window.location.href = "https://accounts.za-zu.com/sign-in";
									return null;
								}
								return (
									<Suspense fallback={<div></div>}>
										<SignInPage />
									</Suspense>
								);
							}}
						/>
						<Route>
							<Redirect to="/sign-in" />
						</Route>
					</Router>
				</SignedOut>

				<Toaster richColors position="bottom-right" expand={true} gap={8} visibleToasts={5} />
			</div>
		</ClerkProvider>
	);
}
