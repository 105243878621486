import React, { Suspense, useEffect } from "react";
import useCampaignStore from "@/store/useCampaignStore";
import CampaignsList from "@/components/CampaignsList";
const CampaignEditor = React.lazy(() => import("@/components/CampaignEditor"));

const Campaigns = () => {
	const { selectedCampaign, setSelectedCampaign } = useCampaignStore();

	useEffect(() => {
		setSelectedCampaign(null);
	}, []);

	return (
		<div className="w-full">
			{selectedCampaign ? (
				<Suspense fallback={<div>Loading...</div>}>
					<CampaignEditor />
				</Suspense>
			) : (
				<CampaignsList />
			)}
		</div>
	);
};

export default Campaigns;
