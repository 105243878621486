import { create } from "zustand";
import { persist } from "zustand/middleware";
import { CampaignStatusEnum } from "@/app/enums/campaign.enum";

type GlobalFilterStore = {
	searchText: string;
	selectedStatuses: CampaignStatusEnum[] | null;
	setSearchText: (searchText: string) => void;
	setSelectedStatuses: (statuses: CampaignStatusEnum[] | null) => void;
};

const useGlobalFilterStore = create<GlobalFilterStore>()(
	persist(
		set => ({
			searchText: "",
			selectedStatuses: null,
			setSearchText: searchText => set({ searchText }),
			setSelectedStatuses: selectedStatuses => set({ selectedStatuses }),
		}),
		{
			name: "global-filter-storage",
		},
	),
);

export default useGlobalFilterStore;
