import { cn } from "@/lib/utils";

interface SpinnerProps extends React.HTMLAttributes<HTMLDivElement> {
	size?: "sm" | "md" | "lg";
	className?: string;
}

const Spinner = ({ size = "md", className, ...props }: SpinnerProps) => {
	const sizeClasses = {
		sm: "h-4 w-4",
		md: "h-6 w-6",
		lg: "h-8 w-8",
	};

	return (
		<div
			role="status"
			className={cn(
				"animate-spin rounded-full border-2",
				"border-background-bg-dim",
				"border-t-brand-primary",
				sizeClasses[size],
				className,
			)}
			{...props}>
			<span className="sr-only">Loading...</span>
		</div>
	);
};

export { Spinner };
