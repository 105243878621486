export function filterData<T>(
	data: T[],
	searchText: string,
	filterFunction: (item: T, searchText: string) => boolean,
): T[] {
	if (!Array.isArray(data)) {
		return [];
	}
	const lowerSearchText = searchText.toLowerCase();
	return data.filter(item => filterFunction(item, lowerSearchText));
}
