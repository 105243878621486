import { ApiCampaign } from "@/components/CampaignsList/CampaignsList";
import { useQuery } from "../useQuery";
import { CampaignProps, getDefaultMetrics } from "@/types/campaign";
import { CampaignStatusEnum } from "@/app/enums/campaign.enum";

export const useCampaigns = ({ onSuccess }: { onSuccess: (data: CampaignProps[]) => void }) => {
	const uri = "/campaigns";
	const { data, error, isLoading } = useQuery<ApiCampaign[]>(uri, {
		onSuccess: data => {
			const sortedCampaigns = data
				.map((campaign: ApiCampaign, index: number) => ({
					id: campaign.id,
					title: campaign.name,
					status: campaign.status as CampaignStatusEnum,
					activeStepId: "",
					sequence:
						campaign.steps?.map((step, stepIndex) => ({
							id: `seq-${campaign.id}-${stepIndex}`,
							order: stepIndex,
							subject: step.subject || "",
							html: step.html || "",
							delayInDays: step.delay_hours || 3,
						})) || [],
					client_id: campaign.client_id,
					workspace_id: "",
					created_by_user_id: campaign.created_by,
					date_created: campaign.created_at,
					date_updated: campaign.updated_at,
					custom_field_requirements: campaign.custom_field_requirements || {},
					campaignMetrics: getDefaultMetrics(),
					sortOrder: index,
					leads: [],
				}))
				.sort(
					(a: CampaignProps, b: CampaignProps) =>
						new Date(b.date_created).getTime() - new Date(a.date_created).getTime(),
				);
			onSuccess?.(sortedCampaigns);
		},
	});
	return { data, isLoading, error };
};
