import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogDescription,
	DialogFooter,
} from "@/components/ui/dialog";
import { ConnectGoogleButton } from "../ConnectAccountsButton/ConnectAccountButton";
import { cn } from "@/lib/utils";
import { Copy } from "lucide-react";
import { toast } from "sonner";

interface GoogleAdminPermissionsDialogProps {
	isOpen: boolean;
	onClose: () => void;
}

export function GoogleAdminPermissionsDialog({ isOpen, onClose }: GoogleAdminPermissionsDialogProps) {
	return (
		<Dialog open={isOpen} onOpenChange={onClose}>
			<DialogContent
				className={cn(
					"flex max-w-[572px] flex-col items-end gap-[23px]",
					"rounded-[12px] border-[0.5px] border-[--background-bg-border]",
					"bg-[--background-bg-base] p-6",
					"shadow-[0px_1px_1px_rgba(0,0,0,0.04),0px_2px_8px_rgba(0,0,0,0.04),0px_3px_17px_rgba(0,0,0,0.04),0px_4px_30px_rgba(0,0,0,0.12)]",
				)}>
				<DialogHeader className="gap-3">
					<DialogTitle>Connect Google account</DialogTitle>
					<DialogDescription>
						Before Za-zu can access your emails, your Google Workspace admin needs to approve it.
					</DialogDescription>
				</DialogHeader>

				<div className="h-[0.5px] w-[524px] bg-[--background-bg-border]" />

				<div className="w-full">
					<h3 className={cn("mb-4 text-[15px] font-medium leading-[22px]", "font-geist text-[--label-label-title]")}>
						Here&apos;s what they need to do:
					</h3>
					<ol className="space-y-4 text-sm">
						<li className="flex gap-2">
							<span
								className={cn(
									"flex h-[22px] w-[22px] min-w-[22px] shrink-0 items-center justify-center",
									"bg-background-bg-brand rounded-[32px]",
									"text-[12px] font-medium leading-[20px] tracking-[-0.24px] text-white",
								)}>
								1
							</span>
							<span>
								Go to Google Workspace security settings at{" "}
								<a
									href="https://admin.google.com/ac/owl"
									target="_blank"
									rel="noopener noreferrer"
									className="text-blue-600 hover:underline">
									admin.google.com/ac/owl
								</a>{" "}
								or find &quot;API controls&quot; in the security menu.
							</span>
						</li>
						<li className="flex gap-2">
							<span
								className={cn(
									"flex h-[22px] w-[22px] min-w-[22px] shrink-0 items-center justify-center",
									"bg-background-bg-brand rounded-[32px]",
									"text-[12px] font-medium leading-[20px] tracking-[-0.24px] text-white",
								)}>
								2
							</span>
							<span>Click &quot;Manage Third-Party App Access&quot; → &quot;Configure new app&quot;</span>
						</li>
						<li className="flex gap-2">
							<span
								className={cn(
									"flex h-[22px] w-[22px] min-w-[22px] shrink-0 items-center justify-center",
									"bg-background-bg-brand rounded-[32px]",
									"text-[12px] font-medium leading-[20px] tracking-[-0.24px] text-white",
								)}>
								3
							</span>
							<div>
								<span>Search for Za-zu using this ID:</span>
								<div className="mt-2 flex flex-wrap items-center gap-2 hyphens-none whitespace-normal">
									<div className="flex items-center gap-2">
										<button
											onClick={() => {
												navigator.clipboard.writeText(
													"649361746370-1k5lru578e73buo6328404pdc49vpds1.apps.googleusercontent.com",
												);
												toast("Copied to clipboard.");
											}}
											className={cn(
												"peer flex h-6 w-6 shrink-0 items-center justify-center",
												"rounded-[5px]",
												"hover:bg-background-bg-hover hover:border-[0.5px] hover:border-[#D9D9D9]",
												"hover:shadow-[0px_1px_1px_0px_rgba(0,0,0,0.06),0px_4px_4px_-1px_rgba(0,0,0,0.02)]",
												"active:scale-95 active:opacity-70",
												"transition-all duration-150",
												"[&_svg]:text-[#666666] hover:[&_svg]:text-[#1A1A1A]",
											)}>
											<Copy size={14} />
										</button>
										<div className="decoration-divider decoration-10p underline-offset-10p break-all underline">
											649361746370-1k5lru578e73buo6328404pdc49vpds1.apps.googleusercontent.com
										</div>
									</div>
								</div>
							</div>
						</li>
						<li className="flex gap-2">
							<span
								className={cn(
									"flex h-[22px] w-[22px] min-w-[22px] shrink-0 items-center justify-center",
									"bg-background-bg-brand rounded-[32px]",
									"text-[12px] font-medium leading-[20px] tracking-[-0.24px] text-white",
								)}>
								4
							</span>
							<span>Mark Za-zu as &quot;Trusted&quot;</span>
						</li>
					</ol>
					<p className={cn("mt-6 text-[15px] font-medium leading-[22px]", "font-geist text-[--label-label-title]")}>
						That&apos;s it. Once your admin does this, you can connect your workspace email to Za-zu.
					</p>
				</div>

				<DialogFooter>
					<ConnectGoogleButton mode="oauth" onClose={onClose} />
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
}
