import { CampaignStatusEnum } from "@/app/enums/campaign.enum";
import { Button } from "@/components/ui/button";
import { useState, useRef, useEffect } from "react";

type FilterModalContentProps = {
	onClose: () => void;
	setSelectedStatuses: (statuses: CampaignStatusEnum[] | null) => void;
	selectedStatuses: CampaignStatusEnum[] | null;
};

const FilterModalContent = ({ onClose, setSelectedStatuses, selectedStatuses }: FilterModalContentProps) => {
	const statusOptions = Object.values(CampaignStatusEnum);
	const [modalSelectedStatuses, setModalSelectedStatuses] = useState<CampaignStatusEnum[]>(selectedStatuses || []);
	const modalRef = useRef<HTMLDivElement>(null);

	const handleCheckboxChange = (status: CampaignStatusEnum, checked: boolean) => {
		if (checked) {
			setModalSelectedStatuses(prev => [...prev, status]);
		} else {
			setModalSelectedStatuses(prev => prev.filter(s => s !== status));
		}
	};

	const handleNoFilterChange = (checked: boolean) => {
		if (checked) {
			setModalSelectedStatuses([]);
		}
	};

	const isNoFilterSelected = modalSelectedStatuses.length === 0;

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
				onClose();
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [onClose]);

	return (
		<div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
			<div className="mx-auto max-w-md rounded-lg bg-white p-6" ref={modalRef}>
				<h2 className="mb-4 text-xl font-semibold text-gray-900">Filter Campaigns</h2>
				<div className="space-y-4">
					<div>
						<label className="flex items-center space-x-2">
							<input
								type="checkbox"
								checked={isNoFilterSelected}
								onChange={e => handleNoFilterChange(e.target.checked)}
								className="form-checkbox h-5 w-5 text-blue-600"
							/>
							<span className="text-gray-700">No filter</span>
						</label>
					</div>
					{statusOptions.map(status => (
						<div key={status}>
							<label className="flex items-center space-x-2">
								<input
									type="checkbox"
									checked={modalSelectedStatuses.includes(status)}
									onChange={e => handleCheckboxChange(status, e.target.checked)}
									className="form-checkbox h-5 w-5 text-blue-600"
								/>
								<span className="text-gray-700">{status}</span>
							</label>
						</div>
					))}
				</div>

				<div className="mt-6 flex justify-end space-x-2">
					<Button
						onClick={() => {
							setSelectedStatuses(modalSelectedStatuses.length > 0 ? modalSelectedStatuses : null);
							onClose();
						}}
						className="focus:shadow-outline rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-600 focus:outline-none">
						Filter
					</Button>
					<Button
						onClick={onClose}
						className="focus:shadow-outline rounded bg-gray-300 px-4 py-2 font-bold text-gray-800 hover:bg-gray-400 focus:outline-none">
						Cancel
					</Button>
				</div>
			</div>
		</div>
	);
};

export default FilterModalContent;
