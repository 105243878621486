"use client";

import { UnderConstruction } from "@/components";

const allLeads = () => {
	return (
		<div className="w-full">
			<UnderConstruction title="All Leads" description="View and manage all your leads in one centralized dashboard." />
		</div>
	);
};

export default allLeads;
