import { useState } from "react";
import { Button } from "@/components/ui/button";
import IconSVG from "../IconSVG/IconSVG";
import Icon from "../Icon/Icon";
import { companyCardPath } from "@/assets/icons/paths";
import { MotionConfig } from "framer-motion";
import { motion } from "framer-motion";
import { ConnectGoogleButton, ConnectMicrosoftButton } from "../ConnectAccountsButton/ConnectAccountButton";
import { useGenerateGoogleOauthUrl } from "@/hooks/accounts/generateGoogleOauthUrl";
import { useGenerateMicrosoftOauthUrl } from "@/hooks/accounts/generateMicrosoftOauthUrl";
import { useAuth } from "@clerk/clerk-react";
import { getRelativeTimeString } from "@/utils/helpers";
import { cn } from "@/lib/utils";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogDescription,
	DialogFooter,
} from "@/components/ui/dialog";
import { EmailAccount } from "../SelectEmailAccounts/SelectEmailAccounts";

type SyncStatus =
	| "init"
	| "syncing"
	| "connecting"
	| "connected"
	| "authenticationError"
	| "connectError"
	| "unset"
	| "disconnected"
	| null;

interface EmailAccountsTableProps {
	accounts: EmailAccount[];
	selectedIds: string[];
	onSelect: (id: string) => void;
	onReconnect?: (account: EmailAccount) => void;
	onRemove: (id: string) => void;
	onCopyEmail?: (email: string) => void;
	onSettings?: (id: string) => void;
	onGoogleClick?: () => void;
}

const DeleteConfirmationModal = ({
	isOpen,
	onClose,
	onConfirm,
	email,
}: {
	isOpen: boolean;
	onClose: () => void;
	onConfirm: () => void;
	email: string;
}) => {
	return (
		<Dialog open={isOpen} onOpenChange={onClose}>
			<DialogContent className="p-4 sm:max-w-[400px]">
				<DialogHeader className="space-y-3">
					<DialogTitle className="text-[15px] font-medium leading-[22px] text-[#1A1A1A]">
						Delete email account
					</DialogTitle>
					<DialogDescription className="py-1 text-[13px] leading-[18px] text-[#666666]">
						Are you sure you want to delete the account {email}? This action cannot be undone.
					</DialogDescription>
				</DialogHeader>
				<DialogFooter className="mt-6">
					<Button variant="ghost" onClick={onClose} className="h-8 px-3 text-sm">
						Cancel
					</Button>
					<Button variant="destructive" onClick={onConfirm} className="text-sm">
						Delete account
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};

const ReconnectButton = ({ account }: { account: EmailAccount }) => {
	const { getToken } = useAuth();
	const { generateGoogleOauthUrl } = useGenerateGoogleOauthUrl();
	const { generateMicrosoftOauthUrl } = useGenerateMicrosoftOauthUrl();

	const handleReconnect = async () => {
		const token = await getToken();
		if (!token) return;

		if (account.sync_type?.includes("google")) {
			await generateGoogleOauthUrl({ access_token: token });
		} else if (account.sync_type?.includes("microsoft")) {
			await generateMicrosoftOauthUrl({ access_token: token });
		}
	};

	return (
		<Button variant="link" className="p-0 text-[13px] text-gray-500 hover:no-underline" onClick={handleReconnect}>
			Reconnect account
		</Button>
	);
};

const STATUS_CONFIG = {
	init: {
		textColor: "text-blue-500",
		bgColor: "bg-blue-500",
		label: "Init",
		showReconnect: false,
	},
	syncing: {
		textColor: "text-blue-500",
		bgColor: "bg-blue-500",
		label: "Syncing",
		showReconnect: false,
	},
	connecting: {
		textColor: "text-blue-500",
		bgColor: "bg-blue-500",
		label: "Connecting",
		showReconnect: false,
	},
	connected: {
		textColor: "text-emerald-500",
		bgColor: "bg-emerald-500",
		label: "Connected",
		showReconnect: false,
	},
	authenticationError: {
		textColor: "text-red-500",
		bgColor: "bg-red-500",
		label: "Error",
		showReconnect: true,
	},
	connectError: {
		textColor: "text-red-500",
		bgColor: "bg-red-500",
		label: "Error",
		showReconnect: true,
	},
	unset: {
		textColor: "text-red-500",
		bgColor: "bg-red-500",
		label: "Error",
		showReconnect: true,
	},
	disconnected: {
		textColor: "text-red-500",
		bgColor: "bg-red-500",
		label: "Error",
		showReconnect: true,
	},
	null: {
		textColor: "text-orange-500",
		bgColor: "bg-orange-500",
		label: "Not Connected",
		showReconnect: true,
	},
} as const;

const getStatusConfig = (status: SyncStatus) => {
	return STATUS_CONFIG[status || "null"];
};

const EmailAccountsTable = ({
	accounts,
	selectedIds,
	onSelect,
	onReconnect,
	onRemove,
	onGoogleClick,
}: EmailAccountsTableProps) => {
	const [hoveredRow, setHoveredRow] = useState<string | null>(null);
	const [isKeyboardNavigation, setIsKeyboardNavigation] = useState(false);
	const [deleteModalState, setDeleteModalState] = useState<{ isOpen: boolean; account: EmailAccount | null }>({
		isOpen: false,
		account: null,
	});

	if (accounts.length === 0) {
		return (
			<div className="flex flex-col items-center justify-center py-8">
				<div className="inline-flex flex-col items-center gap-3">
					<h2 className="self-stretch text-center text-xl font-semibold leading-[27px] text-[--label-label-title]">
						No email accounts connected
					</h2>
					<p className="text-body-head leading-body-head text-center font-normal text-[--label-label-muted]">
						Connect your first email account to start sending campaigns
					</p>
				</div>
			</div>
		);
	}

	const handleDelete = (account: EmailAccount) => {
		setDeleteModalState({ isOpen: true, account });
	};

	const handleConfirmDelete = () => {
		if (deleteModalState.account) {
			onRemove(deleteModalState.account.id);
			setDeleteModalState({ isOpen: false, account: null });
		}
	};

	const getAccountButtons = (account: EmailAccount) => {
		return (
			<div
				className="border-background-bg-border backdrop-blur-modal shadow-low flex items-center gap-1 rounded-lg border-[0.5px] bg-white/80 p-1"
				onClick={e => e.stopPropagation()}>
				{(!account.sync_status || !["syncing", "init", "connected", "connecting"].includes(account.sync_status)) && (
					<>
						<Button
							variant="inboxAction"
							className="hover:bg-background-bg-dim-hover hover:shadow-low flex h-6 w-6 cursor-pointer items-center justify-center px-1 transition-all hover:rounded-[5px]"
							onClick={e => {
								e.stopPropagation();
								onReconnect?.(account);
							}}>
							<Icon version="fa6" name="FaArrowRotateLeft" size="14px" className="text-gray-500" />
						</Button>
						<div className="h-4 w-[1px] bg-gray-200" />
					</>
				)}
				<Button
					variant="inboxAction"
					className="hover:bg-background-bg-dim-hover hover:shadow-low flex h-6 w-6 cursor-pointer items-center justify-center px-1 transition-all hover:rounded-[5px]"
					onClick={e => {
						e.stopPropagation();
						handleDelete(account);
					}}>
					<Icon name="FaTrash" size="14px" className="text-gray-500" />
				</Button>
			</div>
		);
	};

	const handleMouseMove = (accountId: string) => {
		setIsKeyboardNavigation(false);
		setHoveredRow(accountId);
	};

	const handleMouseLeave = () => {
		setHoveredRow(null);
	};

	const getActiveRow = (id: string) => hoveredRow === id;

	return (
		<div className="w-full rounded-lg bg-white">
			<DeleteConfirmationModal
				isOpen={deleteModalState.isOpen}
				onClose={() => setDeleteModalState({ isOpen: false, account: null })}
				onConfirm={handleConfirmDelete}
				email={deleteModalState.account?.email ?? ""}
			/>
			<header>
				<div className="flex h-[52px] items-center justify-between p-4">
					<div className="flex flex-col gap-1">
						<div className="flex items-center gap-2 text-[13px] text-[#397AF1]">
							<IconSVG path={companyCardPath} width={14} height={14} viewBox="0 0 14 14" className="text-[#397AF1]" />
							<span>Sending times: Mon - Fri, 9AM-6PM, EST -4</span>
						</div>
					</div>
					<div className="flex items-center gap-2">
						<ConnectGoogleButton mode="dialog" onClick={onGoogleClick} />
						<ConnectMicrosoftButton />
					</div>
				</div>
			</header>
			<div className="border-t border-gray-200">
				<div
					className="grid h-10 items-center border-b border-gray-200 px-4 text-xs font-medium text-gray-500"
					style={{
						gridTemplateColumns: "56px 1fr 1fr 1fr 1fr 1fr 1fr",
					}}>
					<div />
					<div className="font-medium">Email</div>
					<div className="font-medium">Name</div>
					<div className="font-medium">Daily limit</div>
					<div className="font-medium">Wait between sends</div>
					<div className="font-medium">Status</div>
					<div className="font-medium">Connected at</div>
				</div>
			</div>
			<MotionConfig transition={{ type: "spring", bounce: 0, duration: 0.25 }}>
				<div className="h-[calc(100vh-450px)] w-full overflow-auto">
					<div className="w-full">
						{accounts.map(account => (
							<motion.div
								layout={isKeyboardNavigation}
								key={account.id}
								className="relative border-b border-gray-200"
								onMouseMove={() => handleMouseMove(account.id)}
								onMouseLeave={handleMouseLeave}
								onClick={() => onSelect(account.id)}>
								{getActiveRow(account.id) && (
									<>
										<motion.div
											layoutId={isKeyboardNavigation ? "active-row-bg" : undefined}
											className="absolute inset-0 z-0 bg-gray-200"
										/>
										<motion.div
											layoutId={isKeyboardNavigation ? "active-row-border" : undefined}
											className="bg-label-link absolute bottom-0 left-0 top-0 z-0 w-1"
										/>
									</>
								)}
								<div
									className="group relative z-10 grid h-12 cursor-pointer items-center px-4"
									style={{
										gridTemplateColumns: "56px 1fr 1fr 1fr 1fr 1fr 1fr",
									}}>
									<div className="flex items-center">
										<input
											type="checkbox"
											checked={selectedIds.includes(account.id)}
											onChange={() => onSelect(account.id)}
											onClick={e => e.stopPropagation()}
										/>
									</div>
									<div className="font-medium">{account.email}</div>
									<div>
										<span className="text-[13px] font-normal text-[--label-label-base]">{account.name || "-"}</span>
									</div>
									<div>
										<span className="text-[13px] font-normal text-[--label-label-base]">
											{account.daily_send_limit} emails
										</span>
									</div>
									<div>
										<span className="text-[13px] font-normal text-[--label-label-base]">
											~{account.wait_time_minutes} min
										</span>
									</div>
									<div>
										<span
											className={cn("inline-flex items-center gap-1", getStatusConfig(account.sync_status).textColor)}>
											<div className={cn("h-1.5 w-1.5 rounded-full", getStatusConfig(account.sync_status).bgColor)} />
											{getStatusConfig(account.sync_status).label}
										</span>
									</div>
									<div className="flex items-center justify-between">
										<span>
											{getStatusConfig(account.sync_status).showReconnect ? (
												<ReconnectButton account={account} />
											) : (
												<span className="text-[13px] text-gray-500">{getRelativeTimeString(account.created_at)}</span>
											)}
										</span>

										{hoveredRow === account.id && (
											<div className="flex items-center gap-2">{getAccountButtons(account)}</div>
										)}
									</div>
								</div>
							</motion.div>
						))}
					</div>
				</div>
			</MotionConfig>
		</div>
	);
};

export default EmailAccountsTable;
