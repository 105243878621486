export enum CampaignCreationStepEnum {
	Leads = "Leads",
	Sequences = "Create sequence",
	SelectEmails = "Select email accounts",
}

export enum CampaignStatusEnum {
	Draft = "Draft",
	Active = "Active",
	Paused = "Paused",
	Stopped = "Stopped",
	Completed = "Completed",
}
