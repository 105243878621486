import { useMutation } from "../useMutation";
import { toast } from "sonner";
import { ApiCampaign } from "@/components/CampaignsList/CampaignsList";
import { CampaignProps, CampaignStep, getDefaultMetrics } from "@/types/campaign";
import { CampaignStatusEnum } from "@/app/enums/campaign.enum";
import { useFetch } from "../useQuery";

type FetchCampaignBody = {
	campaign_id: string;
};

// We use mutate here so we can activate things on button push
export function useFetchCampaignDetails({ onSuccess }: { onSuccess: (data: CampaignProps) => void }): {
	isLoading: boolean;
	fetchCampaignDetails: (input: FetchCampaignBody) => Promise<ApiCampaign>;
	fetchError: unknown;
} {
	const fetch = useFetch();

	const fetchCampaignDetails = async ({ input }: { input: FetchCampaignBody }) => {
		if (!input.campaign_id) {
			throw new Error("Campaign ID is required");
		}
		const uri = `/campaigns/${input.campaign_id}`;
		return fetch<ApiCampaign>(uri);
	};

	const { isMutating, mutate, error } = useMutation<ApiCampaign, FetchCampaignBody, void>({
		update: fetchCampaignDetails,
		onSuccess: data => {
			if (data) {
				const sequence =
					data.steps?.map((step: CampaignStep, stepIndex: number) => ({
						id: `seq-${data.id}-${stepIndex}`,
						order: stepIndex,
						subject: step.subject || "",
						html: step.html || "",
						delayInDays: step.delay_hours || 3,
					})) || [];

				const transformedCampaign: CampaignProps = {
					id: data.id,
					title: data.name,
					status: data.status as CampaignStatusEnum,
					activeStepId: sequence.length > 0 ? sequence[0].id : "",
					sequence,
					client_id: data.client_id,
					workspace_id: "",
					created_by_user_id: data.created_by,
					date_created: data.created_at,
					date_updated: data.updated_at,
					custom_field_requirements: data.custom_field_requirements || {},
					campaignMetrics: getDefaultMetrics(),
					hasLeads: data.hasLeads ?? 0,
					leads: [],
				};
				onSuccess?.(transformedCampaign);
			}
		},
		onError: err => {
			console.error("Error fetching campaign details:", err);
			toast.error("Failed to load campaign details");
		},
	});

	return {
		isLoading: isMutating,
		fetchCampaignDetails: mutate,
		fetchError: error,
	};
}
