import { InboxTabs } from "@/app/enums/inbox.enum";
import { useEffect } from "react";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../ui/tooltip";

interface InboxHeaderProps {
	activeTab: InboxTabs;
	setActiveTab: (tab: InboxTabs) => void;
	primaryCount: number;
	searchTerm: string;
	setSearchTerm: (text: string) => void;
	sortOrder: string;
	setSortOrder: (order: string) => void;
	isModalOpen: boolean;
}

const InboxHeader = ({
	activeTab,
	setActiveTab,
	primaryCount,
	searchTerm,
	setSearchTerm,
	sortOrder,
	setSortOrder,
	isModalOpen,
}: InboxHeaderProps) => {
	useEffect(() => {
		const handleKeyDown = (e: KeyboardEvent) => {
			if (e.key === "Tab" && !isModalOpen) {
				e.preventDefault();
				switch (activeTab) {
					case InboxTabs.PRIMARY:
						setActiveTab(InboxTabs.SENT);
						break;
					case InboxTabs.SENT:
						setActiveTab(InboxTabs.DONE);
						break;
					case InboxTabs.DONE:
						setActiveTab(InboxTabs.PRIMARY);
						break;
				}
			}
		};

		window.addEventListener("keydown", handleKeyDown);
		return () => window.removeEventListener("keydown", handleKeyDown);
	}, [activeTab, setActiveTab, isModalOpen]);

	return (
		<TooltipProvider>
			<div className="flex items-center gap-6 self-stretch py-4 pl-14 pr-4">
				<div className="flex flex-1 items-center gap-6">
					<div className="flex items-center gap-6">
						<Tooltip>
							<TooltipTrigger asChild>
								<div
									className="flex cursor-pointer items-end gap-[9px]"
									onClick={() => setActiveTab(InboxTabs.PRIMARY)}>
									<label
										className={`flex text-xl font-semibold leading-[27px] ${activeTab === InboxTabs.PRIMARY
											? "text-[color:var(--label-label-title)]"
											: "text-[color:var(--label-label-faint)]"
											}`}>
										Primary
									</label>
									<label
										className={`flex text-[15px] font-normal leading-[22px] text-[color:var(--label-label-muted)]`}>
										{primaryCount}
									</label>
								</div>
							</TooltipTrigger>
							<TooltipContent>
								Next Split Inbox <kbd>tab</kbd>
							</TooltipContent>
						</Tooltip>
						<Tooltip>
							<TooltipTrigger asChild>
								<div
									className={`cursor-pointer text-xl font-semibold leading-[27px] ${activeTab === InboxTabs.SENT
										? "text-[color:var(--label-label-title)]"
										: "text-[color:var(--label-label-faint)]"
										}`}
									onClick={() => setActiveTab(InboxTabs.SENT)}>
									Sent
								</div>
							</TooltipTrigger>
							<TooltipContent>
								Next Split Inbox <kbd>tab</kbd>
							</TooltipContent>
						</Tooltip>
						<Tooltip>
							<TooltipTrigger asChild>
								<div
									className={`cursor-pointer text-xl font-semibold leading-[27px] ${activeTab === InboxTabs.DONE
										? "text-[color:var(--label-label-title)]"
										: "text-[color:var(--label-label-faint)]"
										}`}
									onClick={() => setActiveTab(InboxTabs.DONE)}>
									Done
								</div>
							</TooltipTrigger>
							<TooltipContent>
								Next Split Inbox <kbd>tab</kbd>
							</TooltipContent>
						</Tooltip>
					</div>
					<div className="ml-auto flex items-center gap-4">
						<input
							type="text"
							value={searchTerm}
							onChange={e => setSearchTerm(e.target.value)}
							placeholder="Search emails..."
							className="rounded border px-3 py-1"
						/>
						<select value={sortOrder} onChange={e => setSortOrder(e.target.value)} className="rounded border px-3 py-1">
							<option value="Newest">Newest</option>
							<option value="Oldest">Oldest</option>
						</select>
					</div>
				</div>
			</div>
		</TooltipProvider>
	);
};
export default InboxHeader;
