import { Button } from "../ui/button";

interface EmptyCampaignsProps {
	onCreateCampaign: () => void;
}

export const EmptyCampaigns = ({ onCreateCampaign }: EmptyCampaignsProps) => {
	return (
		<div className="flex h-full w-full items-center justify-center pt-[72px]">
			<div className="inline-flex flex-col items-center gap-3">
				<div className="inline-flex flex-col items-center gap-3">
					<h2 className="self-stretch text-center text-[20px] font-semibold leading-[27px] text-[--label-label-title]">
						Start building your first campaign
					</h2>
					<p className="max-w-[426px] text-center text-[15px] font-normal leading-[22px] text-[--label-label-muted]">
						The first step to sending outbound with Za-zu is to create a campaign. It&apos;s easy to start—just click
						below.
					</p>
				</div>
				<div className="inline-flex items-start gap-3">
					<Button onClick={onCreateCampaign} variant="default" className="h-8 px-2">
						Create campaign
					</Button>
					<Button onClick={() => {}} variant="outline" className="h-8 px-2 text-[13px] font-medium leading-[18px]">
						Explore sample
					</Button>
				</div>
			</div>
		</div>
	);
};
