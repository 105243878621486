export const formatTimestamp = (timestamp: string | Date | null, expanded = false): string => {
	if (!timestamp) return "";
	const date = new Date(timestamp);
	const now = new Date();
	const isToday = date.toDateString() === now.toDateString();
	const isThisYear = date.getFullYear() === now.getFullYear();

	if (expanded) {
		return `${date
			.toLocaleDateString("en-US", {
				month: "short",
				day: "numeric",
			})
			.replace(",", "")} ${date.toLocaleTimeString("en-US", {
			hour: "numeric",
			minute: "2-digit",
			hour12: true,
		})}`;
	}

	if (isToday) {
		return date.toLocaleTimeString("en-US", {
			hour: "numeric",
			minute: "2-digit",
			hour12: true,
		});
	}

	if (isThisYear) {
		return date.toLocaleDateString("en-US", {
			month: "short",
			day: "numeric",
		});
	}

	const month = (date.getMonth() + 1).toString();
	const day = date.getDate().toString();
	const year = date.getFullYear().toString().slice(-2);

	return `${month}/${day}/${year}`;
};

export const getRelativeTimeString = (timestamp: string | Date): string => {
	const date = new Date(timestamp);
	const now = new Date();
	const diffInMs = now.getTime() - date.getTime();
	const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
	const diffInMonths = Math.floor(diffInDays / 30);
	const diffInYears = Math.floor(diffInDays / 365);

	if (diffInYears > 0) {
		return `${diffInYears} ${diffInYears === 1 ? "year" : "years"} ago`;
	}

	if (diffInMonths > 0) {
		return `${diffInMonths} ${diffInMonths === 1 ? "month" : "months"} ago`;
	}

	if (diffInDays >= 7) {
		const weeks = Math.floor(diffInDays / 7);
		return `${weeks} ${weeks === 1 ? "week" : "weeks"} ago`;
	}

	if (diffInDays > 0) {
		return `${diffInDays} ${diffInDays === 1 ? "day" : "days"} ago`;
	}

	return "Today";
};
