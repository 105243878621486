import { useQuery } from "@/hooks/useQuery";
import type { Account } from "@za-zu/types";

export function useEmailAccounts() {
	const { data, isLoading, error, mutate } = useQuery<Account.Slim[]>("/accounts");

	return {
		accounts: data ?? [],
		isLoading,
		error,
		mutate,
	};
}
