import { useMutation } from "../../hooks/useMutation";
import { ZazuAPI } from "@/utils/zazu-api";
import { toast } from "sonner";

export type GenerateOauthUrlInput = {
	access_token: string;
};

const generateMicrosoftUrl = async ({ input }: { input: GenerateOauthUrlInput }) => {
	const uri = `/accounts/oauth/microsoft/start`;
	const response = await ZazuAPI.get(uri, {
		headers: { Authorization: `Bearer ${input.access_token}` },
	});
	return response.data;
};

type ParsableError = {
	response: {
		data: {
			message: string;
		};
	};
};

function isParsableError(err: unknown): err is ParsableError {
	return (
		typeof err === "object" &&
		err !== null &&
		"response" in err &&
		err.response !== null &&
		typeof err.response === "object" &&
		"data" in err.response &&
		err.response.data !== null &&
		typeof err.response.data === "object" &&
		"message" in err.response.data &&
		typeof err.response.data.message === "string"
	);
}

export function useGenerateMicrosoftOauthUrl(): {
	isGenerating: boolean;
	generateMicrosoftOauthUrl: (input: GenerateOauthUrlInput) => Promise<{ url: string }>;
	generateError: unknown;
} {
	const { isMutating, mutate, error } = useMutation<{ url: string }, GenerateOauthUrlInput, void>({
		update: generateMicrosoftUrl,
		onSuccess: (data, _key, _args) => {
			window.open(data.url, "_blank");
		},
		onError: err => {
			let errorMessage = "Error generating url.";
			if (isParsableError(err)) {
				errorMessage = err.response.data.message;
			}
			toast.error(errorMessage);
			console.error(err);
		},
	});

	return {
		isGenerating: isMutating,
		generateMicrosoftOauthUrl: mutate,
		generateError: error,
	};
}
