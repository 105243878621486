import { ApiCampaign } from "@/components/CampaignsList/CampaignsList";
import { june } from "@/utils/juneAnalytics";
import { toast } from "sonner";
import { useMutation } from "../../hooks/useMutation";
import { usePost } from "../useQuery";

type CreateCampaignBody = {
	name: string;
	client_id: string | null;
	custom_field_requirements: Record<string, unknown>;
};

type ParsableError = {
	response: {
		data: {
			message: string;
		};
	};
};

function isParsableError(err: unknown): err is ParsableError {
	return (
		typeof err === "object" &&
		err !== null &&
		"response" in err &&
		err.response !== null &&
		typeof err.response === "object" &&
		"data" in err.response &&
		err.response.data !== null &&
		typeof err.response.data === "object" &&
		"message" in err.response.data &&
		typeof err.response.data.message === "string"
	);
}

export function useCreateCampaign({ onSuccess }: { onSuccess: (data: ApiCampaign) => void }): {
	isCreatingCampaign: boolean;
	createCampaign: (input: CreateCampaignBody) => Promise<ApiCampaign>;
	createError: unknown;
} {
	const post = usePost<ApiCampaign>();

	const createCampaignMutation = async ({ input }: { input: CreateCampaignBody }) => {
		const uri = `/campaigns`;
		return post(uri, input);
	};
	const { isMutating, mutate, error } = useMutation<ApiCampaign, CreateCampaignBody, void>({
		update: createCampaignMutation,
		onSuccess: (data, _key, args) => {
			onSuccess?.({ ...data, name: args.name });
			toast.success("Campaign created successfully.");
			june.track("campaign_created");
		},
		onError: err => {
			let errorMessage = "Error creating campaign.";
			if (isParsableError(err)) {
				errorMessage = err.response.data.message;
			}
			toast.error(errorMessage);
			console.error(err);
		},
	});

	return {
		isCreatingCampaign: isMutating,
		createCampaign: mutate,
		createError: error,
	};
}
