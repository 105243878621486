import { doubleCheckmarkPath } from "@/assets/icons/paths";
import { ThreadOfEmails } from "@/types/inbox";
import { formatTimestamp } from "@/utils/helpers";
import { AnimatePresence, motion, MotionConfig } from "framer-motion";
import { useCallback, useEffect, useState } from "react";
import EmailModal from "../EmailModal/EmailModal";
import IconSVG from "../IconSVG/IconSVG";
import { Button } from "../ui/button";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../ui/tooltip";

export type InboxListProps = {
	threads: ThreadOfEmails[];
	isLoading?: boolean;
	onModalStateChange: (isOpen: boolean) => void;
};

const InboxList = ({ threads, onModalStateChange }: InboxListProps) => {
	const [hoveredRow, setHoveredRow] = useState<string | null>(null);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [selectedThread, setSelectedThread] = useState<ThreadOfEmails | null>(null);
	const [isKeyboardNavigation, setIsKeyboardNavigation] = useState(false);

	const getThreadButtons = (email: ThreadOfEmails) => {
		const isFirstEmail = threads.indexOf(email) === 0;
		return (
			<div
				className="border-background-bg-border backdrop-blur-modal shadow-low flex items-center gap-1 rounded-lg border-[0.5px] bg-white/80 p-1"
				onClick={e => e.stopPropagation()}>
				<TooltipProvider delayDuration={0} disableHoverableContent>
					<Tooltip>
						<TooltipTrigger asChild>
							<Button
								variant="inboxAction"
								className="hover:bg-background-bg-dim-hover hover:shadow-low flex h-6 w-6 cursor-pointer items-center justify-center px-1 transition-all hover:rounded-[5px]">
								<IconSVG
									path={doubleCheckmarkPath}
									width={14}
									height={14}
									viewBox="0 0 14 14"
									className="text-gray-500"
								/>
							</Button>
						</TooltipTrigger>
						<TooltipContent side={isFirstEmail ? "bottom" : "top"}>Mark as done</TooltipContent>
					</Tooltip>
				</TooltipProvider>
			</div>
		);
	};

	const handleEmailClick = useCallback((thread: ThreadOfEmails) => {
		setSelectedThread(thread);
		setIsModalOpen(true);
	}, []);

	const handleMouseMove = (emailId: string) => {
		setIsKeyboardNavigation(false);
		setHoveredRow(emailId);
	};

	useEffect(() => {
		const handleKeyDown = (e: KeyboardEvent) => {
			if (isModalOpen) return;

			if (e.key === "Enter" && hoveredRow !== null) {
				const emailToOpen = threads.find(thread => thread.id === hoveredRow);
				if (emailToOpen) {
					setSelectedThread(emailToOpen);
					setIsModalOpen(true);
				}
			}

			const currentIndex = threads.findIndex(thread => thread.id === hoveredRow);

			if (e.key === "ArrowDown" || e.key.toLowerCase() === "k") {
				e.preventDefault();
				setIsKeyboardNavigation(true);
				setHoveredRow(null);
				if (currentIndex === threads.length - 1) return;

				const newIndex = currentIndex === -1 ? 0 : currentIndex + 1;
				setHoveredRow(threads[newIndex].id);
			} else if (e.key === "ArrowUp" || e.key.toLowerCase() === "j") {
				e.preventDefault();
				setIsKeyboardNavigation(true);
				setHoveredRow(null);
				if (currentIndex === 0) return;

				const newIndex = currentIndex === -1 ? threads.length - 1 : currentIndex - 1;
				setHoveredRow(threads[newIndex].id);
			}
		};

		window.addEventListener("keydown", handleKeyDown);
		return () => window.removeEventListener("keydown", handleKeyDown);
	}, [hoveredRow, threads, setSelectedThread, setIsModalOpen, isModalOpen]);

	const getActiveRow = (id: string) => hoveredRow === id;

	useEffect(() => {
		onModalStateChange(isModalOpen);
	}, [isModalOpen, onModalStateChange]);

	const handleNavigateThread = async (currentEmail: ThreadOfEmails, direction: "up" | "down") => {
		const currentIndex = threads.findIndex(thread => thread.id === currentEmail.id);
		if (currentIndex === -1) return;

		if ((direction === "up" && currentIndex === 0) || (direction === "down" && currentIndex === threads.length - 1)) {
			setIsModalOpen(false);
			return;
		}

		const nextIndex =
			direction === "up" ? Math.max(0, currentIndex - 1) : Math.min(threads.length - 1, currentIndex + 1);

		setSelectedThread(threads[nextIndex]);
		setHoveredRow(threads[nextIndex].id);
	};

	return (
		<MotionConfig transition={{ type: "spring", bounce: 0, duration: 0.25 }}>
			<div className="h-full w-full overflow-hidden bg-white p-0 shadow">
				<div className="w-full">
					{threads.length > 0 ? (
						threads.map(thread => (
							<motion.div
								layout={isKeyboardNavigation}
								key={thread.id}
								className="relative"
								onMouseMove={() => handleMouseMove(thread.id)}
								onClick={() => handleEmailClick(thread)}>
								{getActiveRow(thread.id) && (
									<>
										<motion.div
											layoutId={isKeyboardNavigation ? "active-row-bg" : undefined}
											className="absolute inset-0 z-0 bg-gray-200"
										/>
										<motion.div
											layoutId={isKeyboardNavigation ? "active-row-border" : undefined}
											className="bg-label-link absolute bottom-0 left-0 top-0 z-0 w-1"
										/>
									</>
								)}
								<div
									className="group relative z-10 grid h-12 cursor-pointer items-center"
									style={{
										gridTemplateColumns: "56px 200px 1fr 100px",
									}}>
									<div className="flex h-full items-center justify-center pl-8 pr-0">
										<div className="h-2 w-2 rounded-full bg-[#397AF1]" />
									</div>
									<div className="flex items-center font-medium">{thread.to.name}</div>
									<div className="flex min-w-0 flex-1 items-center gap-8">
										<div className="line-clamp-1 shrink-0 font-normal text-[--label-label-base]">
											{thread.campaign?.name}
										</div>

										<div className="text-preview leading-preview text-label-label-muted line-clamp-1 min-w-0 flex-1 font-normal">
											{thread.last_message_preview}
										</div>
									</div>
									<div className="flex w-[100px] items-center justify-end">
										{isKeyboardNavigation ? (
											<AnimatePresence mode="wait">
												{hoveredRow === thread.id ? (
													<motion.div
														key="buttons"
														initial={{ opacity: 0 }}
														animate={{ opacity: 1 }}
														exit={{ opacity: 0 }}
														transition={{ duration: 0.05 }}
														className="pr-2">
														{getThreadButtons(thread)}
													</motion.div>
												) : (
													<motion.span
														key="timestamp"
														initial={{ opacity: 0 }}
														animate={{ opacity: 1 }}
														exit={{ opacity: 0 }}
														transition={{ duration: 0.05 }}
														className="text-timestamp leading-timestamp text-label-label-muted block w-full pr-4 text-right">
														{thread.last_message_at ? formatTimestamp(thread.last_message_at) : ""}
													</motion.span>
												)}
											</AnimatePresence>
										) : hoveredRow === thread.id ? (
											<div className="pr-2">{getThreadButtons(thread)}</div>
										) : (
											<span className="text-timestamp leading-timestamp text-label-label-muted block w-full pr-4 text-right">
												{thread.last_message_at ? formatTimestamp(thread.last_message_at) : ""}
											</span>
										)}
									</div>
								</div>
							</motion.div>
						))
					) : (
						<div className="flex h-full items-center justify-center text-gray-400">No emails found.</div>
					)}
				</div>
				{selectedThread && (
					<EmailModal
						isOpen={isModalOpen}
						onClose={() => setIsModalOpen(false)}
						thread={selectedThread}
						onNavigateThread={direction => handleNavigateThread(selectedThread, direction)}
					/>
				)}
			</div>
		</MotionConfig>
	);
};

export default InboxList;
