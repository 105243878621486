import React from "react";
import { motion } from "framer-motion";

interface TextAnimationProps {
	company: string;
	content: React.ReactNode;
}

const COMPANY_COLORS: Record<string, string> = {
	"Electric AI": "#EA3368",
	"Aurora": "#832AD0",
	"Density": "#04082B",
	"AngelList": "#000000",
	"Ledge": "#3A845E",
	"Pod People": "#1C237B",
};

export default function TextAnimation({ company, content }: TextAnimationProps) {
	return (
		<motion.div
			className="flex items-center justify-center text-sm"
			initial={{ opacity: 0, y: 20, backdropFilter: "blur(0px)" }}
			animate={{ opacity: 1, y: 0, backdropFilter: "blur(8px)" }}
			exit={{ opacity: 0, y: -20, backdropFilter: "blur(0px)" }}
			transition={{ duration: 0.5 }}
			style={{
				backgroundColor: "rgba(255, 255, 255, 0.3)",
				padding: "0.5rem 1rem",
				borderRadius: "0.5rem",
			}}>
			<span className="flex items-center gap-x-1">
				<span style={{ color: COMPANY_COLORS[company] }}>{company}</span>
				<span className="text-[#666666]">{content}</span>
			</span>
		</motion.div>
	);
}
