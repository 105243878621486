import { useMutation } from "../../hooks/useMutation";
import { ZazuAPI } from "@/utils/zazu-api";
import { toast } from "sonner";

export type GenerateOauthUrlInput = {
	access_token: string;
};

const generateOauthUrl = async ({ input }: { input: GenerateOauthUrlInput }) => {
	const uri = `/accounts/oauth/google/start`;
	const response = await ZazuAPI.get(uri, {
		headers: { Authorization: `Bearer ${input.access_token}` },
	});
	return response.data;
};

type ParsableError = {
	response: {
		data: {
			message: string;
		};
	};
};

function isParsableError(err: unknown): err is ParsableError {
	return (
		typeof err === "object" &&
		err !== null &&
		"response" in err &&
		err.response !== null &&
		typeof err.response === "object" &&
		"data" in err.response &&
		err.response.data !== null &&
		typeof err.response.data === "object" &&
		"message" in err.response.data &&
		typeof err.response.data.message === "string"
	);
}

export function useGenerateGoogleOauthUrl(): {
	isGenerating: boolean;
	generateGoogleOauthUrl: (input: GenerateOauthUrlInput) => Promise<{ url: string }>;
	generateError: unknown;
} {
	const { isMutating, mutate, error } = useMutation<{ url: string }, GenerateOauthUrlInput, void>({
		update: generateOauthUrl,
		onSuccess: (data, _key, _args) => {
			const handleOAuthMessage = (event: MessageEvent) => {
				if (event.data.type === "GOOGLE_OAUTH_SUCCESS") {
					toast.success("Google account connected successfully");
					window.removeEventListener("message", handleOAuthMessage);
				} else if (event.data.type === "GOOGLE_OAUTH_ERROR") {
					toast.error(event.data.error || "Failed to connect account");
					window.removeEventListener("message", handleOAuthMessage);
				}
			};

			window.addEventListener("message", handleOAuthMessage);
			const popup = window.open(data.url, "google_oauth", "width=600,height=600");

			if (!popup) {
				toast.error("Popup blocked. Please allow popups for this site.");
			}
		},
		onError: err => {
			let errorMessage = "Error generating url.";
			if (isParsableError(err)) {
				errorMessage = err.response.data.message;
			}
			toast.error(errorMessage);
			console.error(err);
		},
	});

	return {
		isGenerating: isMutating,
		generateGoogleOauthUrl: mutate,
		generateError: error,
	};
}
