import { FC } from "react";
import * as FaIcons from "react-icons/fa";
import * as Fa6Icons from "react-icons/fa6";

import cn from "clsx";
import type { IconType } from "react-icons/lib";
import type { IconProps } from "./types";

const Icon: FC<IconProps> = ({
  name,
  size = "1em",
  color,
  className,
  version,
  ...props
}) => {

  const IconSet = version === 'fa6' ? Fa6Icons : FaIcons;
  const IconComponent: IconType = IconSet[name as keyof typeof IconSet];

  if (!IconComponent) {
    throw new Error(`Icon ${name} not found in FontAwesome ${version === 'fa6' ? '6' : '5'} set`);
  }

  const iconClassName = cn(className, color && {
    [color]: color.startsWith('text-')
  });

  return (
    <span {...props}>
      <IconComponent
        size={size}
        className={iconClassName}
        color={color && color.startsWith('text-') ? undefined : color}
      />
    </span>
  );
};

export default Icon;
