import React, { memo, useEffect, useState } from "react";
import { Button } from "../ui/button";
import { Textarea } from "../ui/textarea";
import { Tooltip, TooltipContent, TooltipTrigger } from "../ui/tooltip";

interface EmailReplyFormProps {
	recipientName: string;
	messageText: string;
	onMessageChange: (text: string) => void;
	onSend: (markAsDone?: boolean) => void;
	onDiscard: () => void;
	textareaRef?: React.RefObject<HTMLTextAreaElement>;
	onFocusChange: (isFocused: boolean) => void;
}

export const EmailReplyForm = memo(
	({
		recipientName,
		messageText,
		onMessageChange,
		onSend,
		onDiscard,
		textareaRef,
		onFocusChange,
	}: EmailReplyFormProps) => {
		const [isMac, setIsMac] = useState(false);

		useEffect(() => {
			setIsMac(navigator.platform.toUpperCase().indexOf("MAC") >= 0);
		}, []);

		useEffect(() => {
			const handleKeyDown = (event: KeyboardEvent) => {
				if ((event.metaKey || event.ctrlKey) && event.shiftKey && event.key === ".") {
					event.preventDefault();
					onDiscard();
				}
			};

			window.addEventListener("keydown", handleKeyDown);

			if (textareaRef?.current) {
				textareaRef.current.focus();
			}

			return () => {
				window.removeEventListener("keydown", handleKeyDown);
			};
		}, [onDiscard, textareaRef]);

		return (
			<div className="bg-background-bg-base flex w-full flex-col items-start gap-3 p-4">
				<div className="flex w-full items-center gap-1">
					<div className="flex h-6 items-center gap-1.5 rounded-full bg-[#DFF3E8] px-2">Draft</div>
					<div className="text-label-title text-campaign leading-preview line-clamp-1 flex-1 overflow-hidden text-ellipsis font-medium">
						to {recipientName}
					</div>
				</div>

				<Textarea
					ref={textareaRef}
					className="text-label-title text-body-head leading-body-head w-full resize-none border-none px-0 py-0 font-normal focus-visible:ring-0 focus-visible:ring-offset-0"
					value={messageText}
					onChange={e => onMessageChange(e.target.value)}
					onFocus={() => onFocusChange(true)}
					onBlur={() => onFocusChange(false)}
					onInput={e => {
						const target = e.target as HTMLTextAreaElement;
						target.style.height = "auto";
						target.style.height = `${target.scrollHeight}px`;
					}}
					placeholder="Type your reply"
				/>

				<div className="flex w-full justify-end">
					<div className="flex items-center gap-3">
						<Tooltip>
							<TooltipTrigger asChild>
								<Button
									variant="ghost"
									className="bg-background-bg-shade text-label-label-muted flex h-8 items-center justify-center gap-1.5 rounded-full px-3"
									onClick={onDiscard}>
									<span className="text-[13px] leading-[18px]">Discard</span>
								</Button>
							</TooltipTrigger>
							<TooltipContent>
								<kbd>{isMac ? "⌘" : "ctrl"}</kbd>
								<kbd>shift</kbd>
								<kbd>.</kbd>
							</TooltipContent>
						</Tooltip>

						<Button variant="outline" className="h-8 rounded-full px-3" onClick={() => onSend()}>
							Send
						</Button>

						<Button
							className="bg-background-bg-contrast text-label-label-contrast inline-flex h-8 flex-shrink-0 items-center justify-center gap-1.5 rounded-full px-3 text-[13px] font-medium leading-[18px]"
							onClick={() => onSend(true)}>
							Send + Mark Done
						</Button>
					</div>
				</div>
			</div>
		);
	},
);

EmailReplyForm.displayName = "EmailReplyForm";
