import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { isEqual } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import useSWR, { KeyedMutator, mutate as mutateSWR, preload as preloadSWR, SWRConfiguration, useSWRConfig } from "swr";
import { stableHash } from "swr/_internal";
import useSWRImmutable from "swr/immutable";

import { ZazuAPI } from "@/utils/zazu-api";
/**
 * useFetch is a thin wrapper around useSWR. For more detailed
 * information on usage, refer to the swr docs: https://swr.vercel.app/docs/
 */

const STALE_RESOURCES = new Set<string>();

export type FetchResource<TParams extends Record<string, unknown> | undefined = undefined> = {
	uri: string;
} & (TParams extends NonNullable<TParams> ? { params: TParams } : { params?: TParams });

export type FetchKey<TParams extends Record<string, unknown> | undefined = undefined> =
	TParams extends NonNullable<TParams> ? [string, NonNullable<TParams>] : [string, TParams];

export type Fetcher<TData, TParams extends Record<string, unknown> | undefined = undefined> = (
	resource: FetchResource<TParams>,
) => Promise<TData>;

export type UseFetchArgs<TData, TParams extends Record<string, unknown> | undefined> = {
	resource: FetchResource<TParams> | null | undefined;
	suspense?: boolean;
	shouldThrowOnError?: boolean;
	shouldRetryOnError?: boolean;
	dedupingInterval?: number;
	revalidateOnMount?: boolean;
	refreshInterval?: number;
	keepPreviousData?: boolean;
	errorRetryCount?: number;
	errorRetryInterval?: number;
	INTERNAL__cacheKey?: string;
	fetcher?: Fetcher<TData, TParams>;
	onSuccess?: SWRConfiguration<TData>["onSuccess"];
	onError?: (err: UseFetchError<TData>, key: string, config: SWRConfiguration<TData>) => void;
};

export type UseFetchReturnType<TData, TParams extends Record<string, unknown> | undefined> = {
	data: TData | undefined;
	error: Error;
	isLoading: boolean;
	isRevalidating: boolean;
	mutate: KeyedMutator<TData>;
	refresh: () => Promise<TData | undefined>;
	refetch: (newResource: FetchResource<TParams>) => void;
};

function noop() {
	// empty function used to pass as default value to swr config options below
}

async function baseSWRFetcher<TData, TParams extends Record<string, unknown> | undefined = undefined>([
	uri,
	params,
]: FetchKey<TParams>): Promise<TData> {
	const response = await ZazuAPI.request({
		url: uri,
		method: "GET",
		params,
	});
	return response.data;
}

export function INTERNAL_serializeFetchKey<TParams extends Record<string, unknown> | undefined>(
	fetchKey: FetchKey<TParams> | null,
): string {
	return stableHash(fetchKey);
}

export function INTERNAL_invalidateResource<TParams extends Record<string, unknown> | undefined>(
	fetchKey: FetchKey<TParams>,
) {
	STALE_RESOURCES.add(INTERNAL_serializeFetchKey(fetchKey));
}

export function INTERNAL_getFetchKey<TParams extends Record<string, unknown> | undefined>({
	uri,
	params,
}: FetchResource<TParams>): FetchKey<TParams> {
	return [uri, params] as FetchKey<TParams>;
}

export type PrefillArgs<TData, TParams extends Record<string, unknown> | undefined = undefined> = {
	resource: FetchResource<TParams>;
	update: TData | ((currentData: TData | undefined) => TData | undefined);
};
export async function prefill<TData, TParams extends Record<string, unknown> | undefined = undefined>({
	resource,
	update,
}: PrefillArgs<TData, TParams>): Promise<TData | undefined> {
	const fetchKey = INTERNAL_getFetchKey(resource);
	return await mutateSWR<TData>(fetchKey, update, { revalidate: false });
}

export function useFetchAutoRevalidate<TData, TParams extends Record<string, unknown> | undefined = undefined>(
	args: UseFetchArgs<TData, TParams>,
): UseFetchReturnType<TData, TParams> {
	return useBaseFetch(useSWR, args);
}

export function useFetch<TData, TParams extends Record<string, unknown> | undefined = undefined>(
	args: UseFetchArgs<TData, TParams>,
): UseFetchReturnType<TData, TParams> {
	return useBaseFetch(useSWRImmutable, args);
}
function useBaseFetch<TData, TParams extends Record<string, unknown> | undefined = Record<string, unknown> | undefined>(
	useSWRFetchFn: typeof useSWR,
	args: UseFetchArgs<TData, TParams>,
): UseFetchReturnType<TData, TParams> {
	const [resource, setResource] = useState(args.resource);
	const [originalResource, setOriginalResource] = useState(args.resource);

	// We only want to throw errors after we're done retrying.
	// We preemptively initialize `isErrorThrowingBlocked` always to true (including
	// when the resource changes), to prevent us from throwing the error the
	// first time the error is returned, since we will retry errors by default,
	// and the error returned by swr will be defined before we get a chance to set
	// isErrorThrowingBlocked to true.
	const [isErrorThrowingBlocked, setIsErrorThrowingBlocked] = useState(
		args?.shouldRetryOnError === false ? false : true,
	);

	// If a new resource is passed from the caller, this means the caller is
	// controlling the value of the resource and we should use that value, so
	// we sync the value of our state to the parent value.
	if (!isEqual(originalResource, args.resource)) {
		setOriginalResource(args.resource);
		setResource(args.resource);
		setIsErrorThrowingBlocked(true);
	}

	const { onErrorRetry: onErrorRetrySWR } = useSWRConfig();
	const handleErrorRetry = useCallback<NonNullable<SWRConfiguration<TData>["onErrorRetry"]>>(
		(error, key, config, revalidate, revalidateOpts) => {
			const maxRetryCount = config.errorRetryCount;
			const currentRetryCount = revalidateOpts.retryCount;

			// Never retry on 404 or 401 errors
			const isRetryableError =
				axios.isAxiosError(error) && error.response?.status !== 404 && error.response?.status !== 401;
			const reachedMaxRetryCount = maxRetryCount == null || currentRetryCount > maxRetryCount;

			if (!isRetryableError || reachedMaxRetryCount) {
				if (args?.onError) {
					// @ts-expect-error -- the generic param isn't moving properly through the fetcher
					args?.onError(UseFetchError.fromError<TData, TParams>(error, args.resource), key, config);
				}
				setIsErrorThrowingBlocked(false);
				return;
			}
			setIsErrorThrowingBlocked(true);
			onErrorRetrySWR(error, key, config, revalidate, revalidateOpts);
		},
		[args, onErrorRetrySWR],
	);

	const providedFetcher = args?.fetcher;
	const fetcher = useMemo(
		() =>
			providedFetcher
				? ([uri, params]: FetchKey<TParams>): Promise<TData> =>
						providedFetcher({ uri, params } as unknown as FetchResource<TParams>)
				: baseSWRFetcher,
		[providedFetcher],
	);

	const [fetchKey, serializedKey] = useMemo(() => {
		const fetchKey: FetchKey<TParams> | null = resource == null ? null : INTERNAL_getFetchKey(resource);
		const serializedKey = args?.INTERNAL__cacheKey ?? INTERNAL_serializeFetchKey(fetchKey);
		return [fetchKey, serializedKey];
	}, [args?.INTERNAL__cacheKey, resource]);
	const isMarkedAsStale = STALE_RESOURCES.has(serializedKey);

	const { data, error, isLoading, isValidating, mutate } = useSWRFetchFn<TData>(fetchKey, fetcher, {
		suspense: args?.suspense,
		dedupingInterval: args?.dedupingInterval ?? 500, // by default dedupe requests w/ same key within 500ms
		shouldRetryOnError: args?.shouldRetryOnError ?? true,
		revalidateOnMount: isMarkedAsStale || args?.revalidateOnMount,
		refreshInterval: args?.refreshInterval,
		keepPreviousData: args?.keepPreviousData,
		errorRetryCount: args?.errorRetryCount ?? 3,
		errorRetryInterval: args?.errorRetryInterval ?? 1000,
		onSuccess: args?.onSuccess ?? noop,
		onError: noop,
		onErrorRetry: handleErrorRetry,
	});

	useEffect(() => {
		STALE_RESOURCES.delete(serializedKey);
		return () => {
			STALE_RESOURCES.delete(serializedKey);
		};
	}, [serializedKey]);

	const refresh = useCallback(async () => {
		if (!resource) {
			return;
		}
		return mutate();
	}, [mutate, resource]);

	const refetch = useCallback(
		(newResource: FetchResource<TParams>) => {
			const refetchKey = INTERNAL_getFetchKey(newResource);
			// Preload to start request earlier and make refetching
			// faster.
			preloadSWR(refetchKey, fetcher);

			setResource(newResource);
			setIsErrorThrowingBlocked(true);
		},
		[fetcher],
	);

	const shouldThrowOnError = args?.shouldThrowOnError ?? true;
	if (!args?.suspense && shouldThrowOnError && error != null && !isErrorThrowingBlocked) {
		// We opt to always throw errors (unless explicitly opted-out to aid migration)
		// so that they can be properly caught and handled by our Error Boundaries.
		// Our ErrorBoundary component will automatically clear swr errors
		// when the boundary is reset.
		// Note that when `suspense` is enabled, this is swr's default
		// behavior.
		throw UseFetchError.fromError(error, resource);
	}
	return {
		data,
		error,
		isLoading: isLoading,
		isRevalidating: isValidating,
		mutate,
		refresh,
		refetch,
	};
}

export class UseFetchError<TData, TParams extends Record<string, unknown> | undefined = undefined> extends Error {
	resource: FetchResource<TParams> | null | undefined;
	axiosError: {
		config?: AxiosRequestConfig;
		code?: string;
		request?: unknown;
		response?: AxiosResponse<TData>;
	} | null;

	static fromError<TData, TParams extends Record<string, unknown> | undefined = undefined>(
		error: Error,
		resource: FetchResource<TParams> | null | undefined,
	) {
		let fetchError: UseFetchError<TData, TParams>;
		if (axios.isAxiosError(error)) {
			fetchError = new UseFetchError(error.message, resource, error);
		} else {
			fetchError = new UseFetchError(error.message, resource);
		}
		// Preserving the stack trace
		if (error.stack) {
			fetchError.stack = error.name + ": " + error.message + "\n" + error.stack;
		} else {
			fetchError.stack = "";
		}
		return fetchError;
	}

	constructor(
		message: string,
		resource: FetchResource<TParams> | null | undefined,
		axiosError?: {
			config?: AxiosRequestConfig;
			code?: string;
			request?: unknown;
			response?: AxiosResponse<TData>;
		},
	) {
		super(message); // 'Error' breaks prototype chain here
		Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
		this.resource = resource;
		this.axiosError = axiosError ?? null;
	}
}
