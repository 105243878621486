import { useEffect, useState, useRef, useCallback } from "react";
import { useCampaignStore } from "@/store";
import { Button } from "../ui/button";
import { CampaignProps } from "@/types/campaign";
import { june } from "@/utils/juneAnalytics";
import { filterData } from "@/utils/filterData";
import useGlobalFilterStore from "@/store/useGlobalFilterStore";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { StatusBadge } from "@/components/status-badge";
import { ScrollArea } from "@/components/ui/scroll-area";
import IconSVG from "../IconSVG/IconSVG";
import Icon from "../Icon/Icon";
import openedIcon from "../../assets/images/opened-icon.png";
import repliedIcon from "../../assets/images/replied-icon.png";
import { useSidebar } from "@/components/ui/sidebar";
import { Tooltip, TooltipTrigger, TooltipContent } from "@/components/ui/tooltip";
import { ProgressiveBlur } from "@/components/ui/progressive-blur";
import {
	plusButtonPath,
	companyCardPath,
	sendPaperAirplanePath,
	clickedPath,
	thumbsUpPath,
	sidebarTogglePath,
} from "@/assets/icons/paths";
import { EmptyCampaigns } from "./EmptyCampaigns";
import FilterModalContent from "../FilterModalContent/FilterModalContent";
import { emptyNewCampaign } from "@/utils/campaignTemplates";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from "../ui/dialog";
import { Input } from "../ui/input";
import { useCampaigns } from "@/hooks/campaigns/useCampaigns";
import { useCreateCampaign } from "@/hooks/campaigns/useCreateCampaign";
import { useFetchCampaignDetails } from "@/hooks/campaigns/useFetchCampaignDetails";
import { useCampaignActions } from "@/hooks/campaigns/useCampaignActions";

interface TableHeader {
	icon: string;
	text: string;
	iconColor: string;
	version?: string;
}

function calculatePercentage(value: number, total: number): string {
	if (total === 0) return "-";
	const percentage = (value / total) * 100;
	return percentage.toFixed(2) + "%";
}

function MetricCell({ value, total }: { value: number; total: number }) {
	const percentage = calculatePercentage(value, total);
	return (
		<TableCell className="w-[15%] p-0">
			<div className="flex h-[40px] items-center justify-between gap-1 border-r-[0.5px] border-[#E3E3E3] p-[4px_16px]">
				<span className="text-[13px] font-normal leading-[18px] text-[#1A1A1A]">{value.toLocaleString()}</span>
				<span className="whitespace-nowrap text-[11px] font-normal leading-[16px] text-[#666]">{percentage}</span>
			</div>
		</TableCell>
	);
}

export interface ApiCampaign {
	id: string;
	name: string;
	client_id: string;
	status: string;
	created_by: string;
	created_at: string;
	updated_at: string;
	steps: Array<{
		name: string | null;
		subject: string | null;
		html: string | null;
		delay_hours: number | null;
	}>;
	custom_field_requirements: Record<string, boolean>;
	hasLeads: number;
}

const DeleteConfirmationModal = ({
	isOpen,
	onClose,
	onConfirm,
	campaignName,
}: {
	isOpen: boolean;
	onClose: () => void;
	onConfirm: () => void;
	campaignName: string;
}) => {
	return (
		<Dialog open={isOpen} onOpenChange={onClose}>
			<DialogContent className="p-4 sm:max-w-[400px]" style={{ backdropFilter: "blur(2px)" }}>
				<DialogHeader className="space-y-3">
					<DialogTitle className="text-[15px] font-medium leading-[22px] text-[#1A1A1A]">Delete campaign</DialogTitle>
					<DialogDescription className="py-1 text-[13px] leading-[18px] text-[#666666]">
						Are you sure you want to delete the campaign {campaignName}? This action cannot be undone.
					</DialogDescription>
				</DialogHeader>
				<DialogFooter className="mt-6">
					<Button variant="ghost" onClick={onClose} className="h-8 px-3 text-sm">
						Cancel
					</Button>
					<Button variant="destructive" onClick={onConfirm} className="text-sm">
						Delete campaign
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};

const CampaignsList = () => {
	const { campaigns, setSelectedCampaign, setCampaigns } = useCampaignStore();
	const { searchText, selectedStatuses, setSelectedStatuses } = useGlobalFilterStore();
	const { deleteCampaign } = useCampaignActions();

	const [filteredCampaigns, setFilteredCampaigns] = useState<CampaignProps[]>([]);
	const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
	const [hoveredRow, setHoveredRow] = useState<string | null>(null);
	const [showNameDialog, setShowNameDialog] = useState(false);
	const [newCampaignName, setNewCampaignName] = useState("");
	const [deleteModalState, setDeleteModalState] = useState<{ isOpen: boolean; campaign: CampaignProps | null }>({
		isOpen: false,
		campaign: null,
	});

	const handleDelete = (campaign: CampaignProps) => {
		setDeleteModalState({ isOpen: true, campaign });
	};

	const handleConfirmDelete = async () => {
		if (deleteModalState.campaign) {
			try {
				await deleteCampaign(deleteModalState.campaign.id);
				setDeleteModalState({ isOpen: false, campaign: null });
			} catch (error) {
				console.error("Error deleting campaign:", error);
			}
		}
	};

	const onFetchListSuccess = useCallback((data: CampaignProps[]) => {
		setCampaigns(data);
	}, []);

	const onCreateSuccess = useCallback((data: ApiCampaign) => {
		const newCampaign: CampaignProps = {
			...emptyNewCampaign,
			id: data.id,
			title: data.name,
		};

		setCampaigns([...campaigns, newCampaign]);
		setSelectedCampaign(newCampaign);
		setShowNameDialog(false);
		setNewCampaignName("");
	}, []);

	const onFetchDetailsSuccess = useCallback((data: CampaignProps) => {
		setSelectedCampaign(data);
	}, []);

	const { error, isLoading } = useCampaigns({ onSuccess: onFetchListSuccess });
	const { isCreatingCampaign, createCampaign } = useCreateCampaign({ onSuccess: onCreateSuccess });
	const { fetchCampaignDetails } = useFetchCampaignDetails({ onSuccess: onFetchDetailsSuccess });

	// Effect to filter campaigns based on search text and selected statuses
	useEffect(() => {
		setFilteredCampaigns(
			filterData(campaigns, searchText, (campaign, searchText) => {
				const title = campaign.title || "";
				const matchesSearch = title.toLowerCase().includes(searchText.toLowerCase());
				const matchesStatus = !selectedStatuses?.length || selectedStatuses.includes(campaign.status);
				return matchesSearch && matchesStatus;
			}),
		);
	}, [searchText, campaigns, selectedStatuses]);

	const debounceTextSearch = useRef<NodeJS.Timeout | null>(null);
	const { toggleSidebar, state } = useSidebar();

	// New useEffect for debounced search tracking
	useEffect(() => {
		if (debounceTextSearch.current) {
			clearTimeout(debounceTextSearch.current);
		}

		debounceTextSearch.current = setTimeout(() => {
			if (searchText.trim().length > 1) {
				june?.track("search_campaigns", { search_text: searchText });
			}
		}, 2000);

		return () => {
			if (debounceTextSearch.current) {
				clearTimeout(debounceTextSearch.current);
			}
		};
	}, [searchText]);

	// Add keyboard shortcut handler
	useEffect(() => {
		const handleKeyPress = (e: KeyboardEvent) => {
			// Only trigger if not typing in an input/textarea
			if (e.key.toLowerCase() === "n" && !["INPUT", "TEXTAREA"].includes((e.target as HTMLElement).tagName)) {
				e.preventDefault(); // Prevent the 'n' from being typed
				e.stopPropagation(); // Stop event from bubbling up
				handleNewCampaignClick();
			}
		};

		document.addEventListener("keydown", handleKeyPress);
		return () => document.removeEventListener("keydown", handleKeyPress);
	}, []);

	const handleNewCampaignClick = () => {
		setShowNameDialog(true);
	};

	const handleCreateCampaign = () => {
		if (!newCampaignName.trim() || isCreatingCampaign) return;
		createCampaign({
			name: newCampaignName?.trim(),
			client_id: null,
			custom_field_requirements: {},
		});
	};

	const handleFilterClose = () => {
		setIsFilterModalOpen(false);
	};

	const renderHeader = () => (
		<header>
			<div className="flex h-[52px] items-center justify-between">
				<div className="flex items-center gap-2">
					<Tooltip delayDuration={500}>
						<TooltipTrigger asChild>
							<button
								onClick={toggleSidebar}
								className="flex h-6 w-6 items-center justify-center rounded-sm hover:bg-gray-100"
								aria-label="Toggle sidebar">
								<IconSVG path={sidebarTogglePath} width={16} height={16} viewBox="0 0 16 16" fill="#666666" />
							</button>
						</TooltipTrigger>
						<TooltipContent side="right">
							{state === "expanded" ? "Collapse the sidebar (⌘B)" : "Expand the sidebar (⌘B)"}
						</TooltipContent>
					</Tooltip>
					<h1 className="text-[15px] font-medium leading-[22px] text-[--label-label-title]">Campaigns</h1>
				</div>
				<div className="campaigns-list-actions flex gap-2">
					<Button onClick={() => setIsFilterModalOpen(true)} aria-label="Open filter options" variant="exit">
						Filter
					</Button>
					<Button onClick={handleNewCampaignClick} aria-label="Create new campaign" variant="outline">
						<IconSVG path={plusButtonPath} width={16} height={16} viewBox="0 0 16 16" fill="currentColor" />
						New campaign
					</Button>
				</div>
			</div>
		</header>
	);

	if (isLoading) {
		return <div>Loading campaigns...</div>;
	}

	if (error) {
		return <div>Error loading campaigns: {error.message}</div>;
	}

	return (
		<div className="campaigns-list-wrapper px-4">
			{renderHeader()}
			<DeleteConfirmationModal
				isOpen={deleteModalState.isOpen}
				onClose={() => setDeleteModalState({ isOpen: false, campaign: null })}
				onConfirm={handleConfirmDelete}
				campaignName={deleteModalState.campaign?.title ?? ""}
			/>
			<Dialog open={showNameDialog} onOpenChange={setShowNameDialog}>
				<DialogContent className="sm:max-w-[430px]">
					<DialogHeader>
						<DialogTitle className="pb-4">Name your campaign</DialogTitle>
					</DialogHeader>
					<form
						onSubmit={e => {
							e.preventDefault();
							if (newCampaignName.trim()) {
								handleCreateCampaign();
							}
						}}
						className="flex flex-col gap-4">
						<Input value={newCampaignName} onChange={e => setNewCampaignName(e.target.value)} autoFocus />
						<Button type="submit" disabled={!newCampaignName.trim()}>
							Create campaign
						</Button>
					</form>
				</DialogContent>
			</Dialog>
			{campaigns.length === 0 ? (
				<>
					<div className="-mx-4 h-[0.5px] bg-[--background-bg-border]" />
					<EmptyCampaigns onCreateCampaign={handleNewCampaignClick} />
				</>
			) : (
				<div className="relative">
					<ScrollArea className="h-[calc(100vh-100px)]">
						<div className="campaigns-list-table w-full overflow-hidden rounded-lg border">
							<Table>
								<TableHeader>
									<TableRow className="bg-gray-50">
										<TableHead className="w-1/4 p-0">
											<div className="flex h-[40px] items-center gap-3 border-r-[0.5px] border-[#E3E3E3] p-[4px_16px]">
												<IconSVG path={companyCardPath} width={14} height={14} viewBox="0 0 14 14" fill="#1A1A1A" />
												<span className="text-label-title text-[11px] font-medium leading-[16px]">Name</span>
											</div>
										</TableHead>
										<TableHead className="w-[15%] p-0">
											<div className="flex h-[40px] items-center gap-3 border-r-[0.5px] border-[#E3E3E3] p-[4px_16px]">
												<IconSVG
													path={sendPaperAirplanePath}
													width={14}
													height={14}
													viewBox="0 0 14 14"
													fill="#AAAAAA"
												/>
												<span className="text-label-title whitespace-nowrap text-[11px] font-medium leading-[16px]">
													Sent
												</span>
											</div>
										</TableHead>
										<TableHead className="w-[15%] p-0">
											<div className="flex h-[40px] items-center gap-3 border-r-[0.5px] border-[#E3E3E3] p-[4px_16px]">
												<IconSVG path={clickedPath} width={15} height={14} viewBox="0 0 15 14" fill="#606ACB" />
												<span className="text-label-title whitespace-nowrap text-[11px] font-medium leading-[16px]">
													Clicked
												</span>
											</div>
										</TableHead>
										<TableHead className="w-[15%] p-0">
											<div className="flex h-[40px] items-center gap-3 border-r-[0.5px] border-[#E3E3E3] p-[4px_16px]">
												<img src={openedIcon} alt="Opened icon" width={14} height={14} />
												<span className="text-label-title whitespace-nowrap text-[11px] font-medium leading-[16px]">
													Opened
												</span>
											</div>
										</TableHead>
										<TableHead className="w-[15%] p-0">
											<div className="flex h-[40px] items-center gap-3 border-r-[0.5px] border-[#E3E3E3] p-[4px_16px]">
												<img src={repliedIcon} alt="Replied icon" width={14} height={14} />
												<span className="text-label-title whitespace-nowrap text-[11px] font-medium leading-[16px]">
													Replied
												</span>
											</div>
										</TableHead>
										<TableHead className="w-[15%] p-0">
											<div className="flex h-[40px] items-center gap-3 border-r-[0.5px] border-[#E3E3E3] p-[4px_16px]">
												<IconSVG path={thumbsUpPath} width={15} height={14} viewBox="0 0 15 14" fill="#5FA97B" />
												<span className="text-label-title whitespace-nowrap text-[11px] font-medium leading-[16px]">
													Positive Reply
												</span>
											</div>
										</TableHead>
									</TableRow>
								</TableHeader>
								<TableBody>
									{filteredCampaigns.map((campaign, index) => (
										<TableRow
											key={campaign.id}
											className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"} cursor-pointer hover:bg-[--background-bg-dim-hover]`}
											onClick={() => fetchCampaignDetails({ campaign_id: campaign.id ?? "" })}
											onMouseEnter={() => setHoveredRow(campaign.id)}
											onMouseLeave={() => setHoveredRow(null)}>
											<TableCell className="w-1/4 p-0">
												<div className="flex h-[40px] items-center justify-between gap-1 border-r-[0.5px] border-[#E3E3E3] p-[4px_16px]">
													<span className="flex-grow truncate">{campaign.title}</span>
													<div className="flex items-center gap-2">
														{hoveredRow === campaign.id && (
															<Button
																variant="inboxAction"
																className="hover:bg-background-bg-dim-hover hover:shadow-low flex h-6 w-6 cursor-pointer items-center justify-center px-1 transition-all hover:rounded-[5px]"
																onClick={e => {
																	e.stopPropagation();
																	handleDelete(campaign);
																}}>
																<Icon name="FaTrash" size="14px" className="text-gray-500" />
															</Button>
														)}
														<StatusBadge
															status={
																campaign.status.toLowerCase() as "active" | "draft" | "completed" | "paused" | "stopped"
															}
														/>
													</div>
												</div>
											</TableCell>
											<TableCell className="w-[15%] p-0">
												<div className="flex h-[40px] items-center border-r-[0.5px] border-[#E3E3E3] p-[4px_16px]">
													<span className="text-[13px] font-normal leading-[18px] text-[#1A1A1A]">
														{campaign.campaignMetrics?.sent.toLocaleString() ?? "0"}
													</span>
												</div>
											</TableCell>
											<MetricCell
												value={campaign.campaignMetrics?.clicked ?? 0}
												total={campaign.campaignMetrics?.sent ?? 0}
											/>
											<MetricCell
												value={campaign.campaignMetrics?.opened ?? 0}
												total={campaign.campaignMetrics?.sent ?? 0}
											/>
											<MetricCell
												value={campaign.campaignMetrics?.replied ?? 0}
												total={campaign.campaignMetrics?.sent ?? 0}
											/>
											<MetricCell
												value={campaign.campaignMetrics?.positiveReplies ?? 0}
												total={campaign.campaignMetrics?.sent ?? 0}
											/>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</div>
					</ScrollArea>
					<ProgressiveBlur
						orientation="vertical"
						className="pointer-events-none absolute bottom-0 h-16 w-full"
						style={{ background: "linear-gradient(to top, white, transparent)" }}
					/>
				</div>
			)}
			{isFilterModalOpen && (
				<FilterModalContent
					onClose={handleFilterClose}
					selectedStatuses={selectedStatuses}
					setSelectedStatuses={setSelectedStatuses}
				/>
			)}
		</div>
	);
};

export default CampaignsList;
