import { create } from "zustand";
import { persist } from "zustand/middleware";
import { CampaignProps } from "@/types/campaign";
import { CampaignCreationStepEnum } from "@/app/enums/campaign.enum";

type useCampaignStoreProps = {
	campaigns: CampaignProps[];
	selectedCampaign: CampaignProps | null;
	activeCampaignStep: CampaignCreationStepEnum;
	previewMode: boolean;
	defaultTestEmail: string;
	setSelectedCampaign: (value: CampaignProps | null) => void;
	setDefaultTestEmail: (value: string) => void;
	setPreviewMode: (value: boolean) => void;
	setActiveCampaignStep: (value: CampaignCreationStepEnum) => void;
	updateHtmlInActiveStep: (newHtml: string) => void;
	setCampaigns: (campaigns: CampaignProps[]) => void;
};

const useCampaignStore = create<useCampaignStoreProps>()(
	persist(
		(set, get) => ({
			campaigns: [],
			selectedCampaign: null,
			activeCampaignStep: CampaignCreationStepEnum.Leads,
			previewMode: false,
			defaultTestEmail: "",
			setSelectedCampaign: value => {
				set(_state => {
					if (!value) return { selectedCampaign: value };

					const sortedSequence = value.sequence.sort((a, b) => a.order - b.order);
					const updatedValue = {
						...value,
						sequence: sortedSequence,
						activeStepId: value.activeStepId || (sortedSequence.length > 0 ? sortedSequence[0].id : ""),
					};
					return { selectedCampaign: updatedValue };
				});
			},
			setDefaultTestEmail: value => set({ defaultTestEmail: value }),
			setPreviewMode: value => set({ previewMode: value }),
			setActiveCampaignStep: value => set({ activeCampaignStep: value }),
			updateHtmlInActiveStep: (newHtml: string) => {
				const { selectedCampaign } = get();
				if (selectedCampaign && selectedCampaign.activeStepId) {
					const updatedSequence = selectedCampaign.sequence.map(step => {
						if (step.id === selectedCampaign.activeStepId) {
							return { ...step, html: newHtml };
						}
						return step;
					});
					set({
						selectedCampaign: { ...selectedCampaign, sequence: updatedSequence },
					});
				}
			},
			setCampaigns: campaigns => {
				const validCampaigns = Array.isArray(campaigns)
					? campaigns.map(campaign => ({
							...campaign,
							sequence: campaign.sequence?.sort((a, b) => a.order - b.order) || [],
						}))
					: [];

				set({
					campaigns: validCampaigns,
					selectedCampaign: null,
				});
			},
		}),
		{
			name: "campaigns-storage",
		},
	),
);

export default useCampaignStore;
