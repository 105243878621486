import { useDelete, usePut, usePost, useFetch } from "@/hooks/useQuery";
import useCampaignStore from "@/store/useCampaignStore";
import { CampaignProps } from "@/types/campaign";
import { toast } from "sonner";
import { CampaignStatusEnum } from "@/app/enums/campaign.enum";

interface UseCampaignActionsReturn {
	saveCampaign: (campaign: CampaignProps) => Promise<void>;
	deleteCampaign: (campaignId: string, onSuccess?: () => void) => Promise<void>;
	publishCampaign: () => Promise<void>;
	saveDraftCampaign: () => Promise<void>;
	pauseCampaign: () => Promise<void>;
	createEmailAccountMemberships: (accountIds: string[]) => Promise<void>;
}

interface EmailAccountMembership {
	id: string;
	email_account_id: string;
	campaign_id: string;
	created_at: string;
}

export const useCampaignActions = (): UseCampaignActionsReturn => {
	const { campaigns, setCampaigns, selectedCampaign, setSelectedCampaign } = useCampaignStore();
	const putFn = usePut();
	const postFn = usePost();
	const deleteFn = useDelete();
	const getFn = useFetch();

	const saveCampaign = async (campaign: CampaignProps) => {
		try {
			const backendCampaign = {
				name: campaign.title,
				client_id: campaign.client_id || null,
				status: campaign.status.toLowerCase(),
				steps: campaign.sequence
					.sort((a, b) => a.order - b.order)
					.map((step, index) => ({
						name: `Step ${index + 1}`,
						subject: step.subject || "",
						html: step.html || "",
						delay_hours: index === 0 ? 0 : step.delayInDays || 3,
					})),
				custom_field_requirements: campaign.custom_field_requirements || {},
			};

			await putFn(`/campaigns/${campaign.id}`, backendCampaign);

			const updatedCampaign = {
				...campaign,
				sequence: campaign.sequence.sort((a, b) => a.order - b.order),
			};

			const updatedCampaigns = campaigns.map(c => (c.id === campaign.id ? updatedCampaign : c));

			setCampaigns(updatedCampaigns);
			if (selectedCampaign?.id === campaign.id) {
				setSelectedCampaign(updatedCampaign);
			}
			toast.success("Campaign saved successfully", {
				duration: 2000,
			});
		} catch (error) {
			toast.error("Failed to save campaign");
			console.error("Failed to save campaign:", error);
			throw error;
		}
	};

	const deleteCampaign = async (campaignId: string, onSuccess?: () => void) => {
		try {
			await deleteFn(`/campaigns/${campaignId}`);
			setCampaigns(campaigns.filter(c => c.id !== campaignId));
			toast.success("Campaign deleted successfully", {
				duration: 2000,
			});
			onSuccess?.();
		} catch (error) {
			console.error("Error deleting campaign:", error);
			toast.error("Failed to delete campaign");
			throw error;
		}
	};

	const publishCampaign = async () => {
		if (!selectedCampaign) return;

		const updatedCampaign = {
			...selectedCampaign,
			status: CampaignStatusEnum.Active,
		};

		try {
			await saveCampaign(updatedCampaign);
			toast.success("Campaign published successfully", {
				duration: 2000,
			});
		} catch (error) {
			console.error("Failed to publish campaign:", error);
			const errorMessage = error instanceof Error ? error.message : "Unknown error";
			throw new Error(`Failed to publish campaign: ${errorMessage}`);
		}
	};

	const saveDraftCampaign = async () => {
		if (!selectedCampaign) return;

		const updatedCampaign = {
			...selectedCampaign,
			status: CampaignStatusEnum.Draft,
		};

		try {
			await saveCampaign(updatedCampaign);
			toast.success("Campaign saved as draft", {
				duration: 2000,
			});
		} catch (error) {
			console.error("Failed to save draft:", error);
			toast.error("Failed to save draft");
			throw error;
		}
	};

	const pauseCampaign = async () => {
		if (!selectedCampaign) return;

		const updatedCampaign = {
			...selectedCampaign,
			status: CampaignStatusEnum.Paused,
		};

		try {
			await saveCampaign(updatedCampaign);
			toast.success("Campaign paused successfully", {
				duration: 2000,
			});
		} catch (error) {
			console.error("Failed to pause campaign:", error);
			toast.error("Failed to pause campaign");
			throw error;
		}
	};

	const createEmailAccountMemberships = async (accountIds: string[]) => {
		if (!selectedCampaign) return;

		try {
			let existingMemberships: EmailAccountMembership[];
			try {
				existingMemberships = (await getFn(`/email-account-memberships?campaign_id=${selectedCampaign.id}`, {
					method: "GET",
				})) as EmailAccountMembership[];
			} catch (error) {
				console.error("Failed to fetch existing memberships:", error);
				throw new Error("Failed to fetch existing email accounts");
			}

			const existingAccountIds = new Set(existingMemberships.map(m => m.email_account_id));
			const membershipsByAccountId = new Map(existingMemberships.map(m => [m.email_account_id, m]));

			const accountsToAdd = accountIds.filter(id => !existingAccountIds.has(id));
			const accountsToRemove = Array.from(existingAccountIds).filter(id => !accountIds.includes(id));

			if (accountsToRemove.length > 0) {
				try {
					const deletePromises = accountsToRemove
						.map(accountId => {
							const membership = membershipsByAccountId.get(accountId);
							if (membership) {
								console.log("Deleting membership:", membership.id);
								return deleteFn(`/email-account-memberships/${membership.id}`);
							}
							return null;
						})
						.filter(Boolean);

					await Promise.all(deletePromises);
					console.log("Successfully deleted memberships");
				} catch (error) {
					console.error("Failed to remove email accounts:", error);
					throw new Error("Failed to remove selected email accounts");
				}
			}

			if (accountsToAdd.length > 0) {
				try {
					console.log("Adding new memberships:", accountsToAdd);
					await Promise.all(
						accountsToAdd.map(accountId => {
							console.log("Adding membership for account:", accountId);
							return postFn("/email-account-memberships", {
								email_account_id: accountId,
								campaign_id: selectedCampaign.id,
							});
						}),
					);
					console.log("Successfully added new memberships");
				} catch (error) {
					console.error("Failed to add new email accounts:", error);
					throw new Error("Failed to add new email accounts");
				}
			}
		} catch (error) {
			console.error("Failed to update email account memberships:", error);
			throw error;
		}
	};

	return {
		saveCampaign,
		deleteCampaign,
		publishCampaign,
		saveDraftCampaign,
		pauseCampaign,
		createEmailAccountMemberships,
	};
};
