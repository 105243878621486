export const csvUploadPath = [
    {
        d: "M18.0001 17.1867L19.0509 14.0185C19.2248 13.4943 19.7907 13.2103 20.3149 13.3842C20.8391 13.5581 21.1231 14.124 20.9492 14.6482L19.2613 19.7369C19.0775 20.2914 18.5784 20.6667 18.0001 20.6667C17.4217 20.6667 16.9227 20.2914 16.7388 19.737L15.0509 14.6482C14.8771 14.124 15.1611 13.5581 15.6853 13.3842C16.2095 13.2103 16.7754 13.4943 16.9492 14.0185L18.0001 17.1867Z"
    },
    {
        d: "M8.34041 15.9123C8.2141 14.3167 9.66819 13.3333 11.0001 13.3333C11.6263 13.3333 12.1566 13.4726 12.593 13.7482C13.0262 14.0217 13.2854 14.3785 13.4524 14.6714C13.726 15.1512 13.5587 15.7619 13.0789 16.0354C12.5992 16.3089 11.9885 16.1417 11.7149 15.6619C11.6444 15.5382 11.5869 15.4783 11.5252 15.4393C11.4668 15.4024 11.324 15.3333 11.0001 15.3333C10.7551 15.3333 10.5634 15.4204 10.452 15.5194C10.3622 15.5992 10.3364 15.6705 10.3337 15.7269C10.3579 15.752 10.4226 15.8027 10.5783 15.8666C10.7072 15.9196 10.8446 15.9562 10.9557 15.9794C11.0095 15.9906 11.0527 15.9978 11.08 16.0019C11.0936 16.004 11.103 16.0052 11.1074 16.0057L11.1102 16.0061L11.1192 16.0071L11.143 16.01C11.1618 16.0123 11.1866 16.0157 11.2164 16.0202C11.276 16.0291 11.3571 16.0427 11.4531 16.0628C11.6413 16.1021 11.9048 16.1696 12.1817 16.2833C12.6647 16.4817 13.5727 16.9891 13.6597 18.0877C13.7861 19.6833 12.3319 20.6667 11.0001 20.6667C10.3738 20.6667 9.84359 20.5274 9.40712 20.2518C8.97396 19.9783 8.71473 19.6215 8.54773 19.3286C8.27421 18.8488 8.44142 18.2381 8.92121 17.9646C9.401 17.6911 10.0117 17.8583 10.2852 18.3381C10.3558 18.4618 10.4133 18.5217 10.475 18.5607C10.5334 18.5976 10.6762 18.6667 11.0001 18.6667C11.2451 18.6667 11.4368 18.5796 11.5481 18.4806C11.638 18.4008 11.6638 18.3295 11.6664 18.2731C11.6423 18.248 11.5776 18.1973 11.4219 18.1334C11.293 18.0804 11.1556 18.0438 11.0444 18.0206C10.9907 18.0093 10.9475 18.0022 10.9201 17.9981C10.9065 17.996 10.8972 17.9948 10.8928 17.9943C10.8906 17.994 10.89 17.9939 10.89 17.9939L10.881 17.9929L10.8572 17.99C10.8384 17.9877 10.8136 17.9843 10.7837 17.9798C10.7241 17.9709 10.643 17.9573 10.5471 17.9372C10.3589 17.8979 10.0954 17.8304 9.81846 17.7167C9.33547 17.5183 8.42738 17.0109 8.34041 15.9123ZM11.6791 18.2904C11.6791 18.2904 11.6762 18.2876 11.6735 18.2811C11.6783 18.2871 11.6791 18.2904 11.6791 18.2904ZM10.3211 15.7096C10.3211 15.7096 10.324 15.7124 10.3266 15.7189C10.3219 15.7129 10.3211 15.7096 10.3211 15.7096Z"
    },
    {
        d: "M5.63513 15.8302C4.95599 15.1677 3.84485 15.1677 3.1657 15.8302C2.50043 16.4792 2.50043 17.5208 3.1657 18.1698C3.84485 18.8323 4.95599 18.8323 5.63513 18.1698C6.03047 17.7841 6.66359 17.792 7.04924 18.1873C7.43489 18.5827 7.42703 19.2158 7.03169 19.6014C5.57571 21.0217 3.22513 21.0217 1.76914 19.6014C0.299284 18.1676 0.299284 15.8324 1.76914 14.3986C3.22513 12.9783 5.57571 12.9783 7.03169 14.3986C7.42703 14.7842 7.43489 15.4173 7.04924 15.8127C6.66359 16.208 6.03047 16.2159 5.63513 15.8302Z"
    },
    {
        d: "M11.0001 2.5H7.00006C5.89549 2.5 5.00006 3.39543 5.00006 4.5V10.5C5.00006 11.0523 4.55235 11.5 4.00006 11.5C3.44778 11.5 3.00006 11.0523 3.00006 10.5V4.5C3.00006 2.29086 4.79092 0.5 7.00006 0.5H11.2575C12.3183 0.5 13.3357 0.92143 14.0859 1.67157L17.8285 5.41421C18.5787 6.16436 19.0001 7.18177 19.0001 8.24264V10.5C19.0001 11.0523 18.5523 11.5 18.0001 11.5C17.4478 11.5 17.0001 11.0523 17.0001 10.5V8.5H15.0001C12.791 8.5 11.0001 6.70914 11.0001 4.5V2.5ZM16.0858 6.5H15.0001C13.8955 6.5 13.0001 5.60457 13.0001 4.5V3.41419L16.0858 6.5Z"
    }
]; 