import { useState, useEffect, useCallback } from "react";
import { useEmailAccounts } from "@/hooks/accounts/useEmailAccounts";
import { EmailAccountsTable } from "../EmailAccountsTable";
import { ConnectGoogleButton, ConnectMicrosoftButton } from "../ConnectAccountsButton/ConnectAccountButton";
import { useDelete, useFetch } from "@/hooks/useQuery";
import { toast } from "sonner";
import { useCampaignStore } from "@/store";
import { GoogleAdminPermissionsDialog } from "../GoogleAdminPermissionsDialog";
import { useAuth } from "@clerk/clerk-react";
import { useGenerateMicrosoftOauthUrl } from "@/hooks/accounts/generateMicrosoftOauthUrl";

type SyncStatus =
	| "init"
	| "syncing"
	| "connecting"
	| "connected"
	| "authenticationError"
	| "connectError"
	| "unset"
	| "disconnected"
	| null;

export interface EmailAccount {
	id: string;
	name?: string | null;
	email: string;
	sync_type?: "imap" | "smtp" | "other" | "ee:google" | "ee:microsoft" | null;
	daily_send_limit: number;
	wait_time_minutes: number;
	locked_at: string | null;
	created_at: string;
	updated_at: string;
	sync_status: SyncStatus;
}

interface EmailAccountMembership {
	id: string;
	email_account_id: string;
	campaign_id: string;
	created_at: string;
}

interface SelectEmailAccountsProps {
	onSelectedAccountsChange?: (ids: string[]) => void;
	shouldFetchMemberships?: boolean;
}

const transformSyncStatus = (status: string | null): SyncStatus => {
	if (!status) return "connected";
	if (
		[
			"init",
			"syncing",
			"connecting",
			"connected",
			"authenticationError",
			"connectError",
			"unset",
			"disconnected",
		].includes(status)
	) {
		return status as SyncStatus;
	}
	return "connected";
};

const SelectEmailAccounts = ({
	onSelectedAccountsChange,
	shouldFetchMemberships = false,
}: SelectEmailAccountsProps) => {
	const { accounts: rawAccounts, isLoading, mutate } = useEmailAccounts();
	const [selectedIds, setSelectedIds] = useState<string[]>([]);
	const deleteAccount = useDelete();
	const fetch = useFetch();
	const { selectedCampaign } = useCampaignStore();
	const [hasFetched, setHasFetched] = useState(false);
	const [isGoogleDialogOpen, setIsGoogleDialogOpen] = useState(false);
	const { getToken } = useAuth();
	const { generateMicrosoftOauthUrl } = useGenerateMicrosoftOauthUrl();

	const handleOnGoogleClick = useCallback(async () => {
		setIsGoogleDialogOpen(true);
	}, []);

	useEffect(() => {
		if (!shouldFetchMemberships || !selectedCampaign || hasFetched) return;

		const fetchMemberships = async () => {
			try {
				const memberships = await fetch<EmailAccountMembership[]>(
					`/email-account-memberships?campaign_id=${selectedCampaign.id}`,
				);
				const existingAccountIds = memberships.map(m => m.email_account_id);
				setSelectedIds(existingAccountIds);
				onSelectedAccountsChange?.(existingAccountIds);
				setHasFetched(true);
			} catch (error) {
				console.error("Failed to fetch email account memberships:", error);
				toast.error("Failed to load existing email accounts");
			}
		};

		fetchMemberships();
	}, [selectedCampaign, fetch, onSelectedAccountsChange, hasFetched]);

	// Transform accounts to ensure sync_status is the correct type
	const accounts = rawAccounts.map(account => ({
		...account,
		sync_status: transformSyncStatus(account.sync_status),
	}));

	const toggleSelect = (id: string) => {
		const newSelectedIds = selectedIds.includes(id)
			? selectedIds.filter(existingId => existingId !== id)
			: [...selectedIds, id];

		setSelectedIds(newSelectedIds);
		onSelectedAccountsChange?.(newSelectedIds);
	};

	const removeAccount = async (accountId: string) => {
		try {
			await deleteAccount(`/accounts/${accountId}`);
			// Refresh the accounts list after deletion
			await mutate();
			toast.success("Account deleted successfully");
		} catch (error) {
			console.error("Failed to delete account:", error);
			toast.error("Failed to delete account");
		}
	};

	const handleOnRecconnect = useCallback(async (account: EmailAccount) => {
		if (account.sync_type === "ee:google") {
			handleOnGoogleClick();
		} else {
			const token = await getToken();
			await generateMicrosoftOauthUrl({ access_token: token ?? "" });
		}
	}, []);

	if (isLoading) {
		return (
			<div className="mt-[44px] flex flex-col items-center">
				<div className="w-full max-w-[555px] p-6">
					<div className="flex flex-col items-center justify-center py-8">
						<div className="inline-flex flex-col items-center gap-3">
							<h2 className="self-stretch text-center text-xl font-semibold leading-[27px] text-[--label-label-title]">
								Loading email accounts...
							</h2>
						</div>
					</div>
				</div>
			</div>
		);
	}
	return (
		<>
			<GoogleAdminPermissionsDialog isOpen={isGoogleDialogOpen} onClose={() => setIsGoogleDialogOpen(false)} />
			{accounts.length === 0 ? (
				<div className="mt-[44px] flex flex-col items-center">
					<div className="w-full max-w-[555px] p-2">
						<div className="flex flex-col items-center justify-center py-2">
							<div className="inline-flex flex-col items-center gap-3">
								<h2 className="self-stretch text-center text-xl font-semibold leading-[27px] text-[--label-label-title]">
									Connect any number of email accounts.
								</h2>
								<p className="text-body-head leading-body-head text-center font-normal text-[--label-label-muted]">
									Add as many accounts as you&apos;d like. Check out{" "}
									<a
										href="https://www.za-zu.com/blog/handbook#the-infrastructure"
										target="_blank"
										rel="noopener noreferrer"
										className="text-body-head leading-body-head font-medium text-[--label-label-base]">
										our guide
									</a>{" "}
									on how to best set up those accounts for maximum deliverability
								</p>
							</div>

							<div className="mt-8 flex w-[224px] flex-col items-start gap-3">
								<ConnectGoogleButton mode="dialog" onClick={handleOnGoogleClick} />
								<ConnectMicrosoftButton />
							</div>
						</div>
					</div>
				</div>
			) : (
				<EmailAccountsTable
					accounts={accounts}
					selectedIds={selectedIds}
					onSelect={toggleSelect}
					onRemove={removeAccount}
					onGoogleClick={handleOnGoogleClick}
					onReconnect={handleOnRecconnect}
				/>
			)}
		</>
	);
};

export default SelectEmailAccounts;
