import * as React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { ChevronDown, User, Settings, LogOut } from "lucide-react";
import { useClerk, useUser } from "@clerk/clerk-react";
import { cn } from "@/lib/utils";

interface UserMenuItemProps {
	icon: React.ElementType;
	children: React.ReactNode;
	onClick?: () => void;
	href?: string;
	className?: string;
}

function UserMenuItem({ icon: Icon, children, onClick, href, className }: UserMenuItemProps) {
	const { openUserProfile } = useClerk();
	const content = (
		<>
			<Icon className="mr-2 h-4 w-4" />
			{children}
		</>
	);

	if (children === "Profile") {
		return (
			<Button variant="ghost" className={`w-full justify-start ${className}`} onClick={() => openUserProfile()}>
				{content}
			</Button>
		);
	}

	if (href) {
		return (
			<Button variant="ghost" className={`w-full justify-start ${className}`} asChild>
				<a href={href}>{content}</a>
			</Button>
		);
	}

	return (
		<Button variant="ghost" className={`w-full justify-start ${className}`} onClick={onClick}>
			{content}
		</Button>
	);
}

export default function UserProfile() {
	const [isOpen, setIsOpen] = React.useState(false);
	const { signOut } = useClerk();
	const { user } = useUser();

	const handleSignOut = async () => {
		await signOut({ redirectUrl: "/sign-in" });
	};

	if (!user) return null;

	return (
		<div className="mt-auto">
			<Popover open={isOpen} onOpenChange={setIsOpen}>
				<PopoverTrigger asChild>
					<Button
						variant="ghost"
						className={cn(
							"relative flex h-8 w-full items-center justify-start gap-2 rounded-[6px] px-0",
							"group-data-[collapsible=icon]:w-8 group-data-[collapsible=icon]:justify-center",
						)}
						role="combobox"
						aria-expanded={isOpen}>
						<Avatar
							className={cn(
								"mr-2 h-8 w-8",
								"group-data-[collapsible=icon]:mr-0 group-data-[collapsible=icon]:h-4 group-data-[collapsible=icon]:w-4",
							)}>
							<AvatarImage src={user.imageUrl} alt={user.fullName || "User avatar"} />
							<AvatarFallback className="text-xs group-data-[collapsible=icon]:text-[10px]">
								{user.firstName?.charAt(0) || "U"}
							</AvatarFallback>
						</Avatar>
						<div className="max-w-[140px] flex-1 text-left group-data-[collapsible=icon]:hidden">
							<p className="text-sm font-medium leading-none">{user.fullName}</p>
							<p className="text-muted-foreground mt-1 truncate text-xs">{user.primaryEmailAddress?.emailAddress}</p>
						</div>
						<motion.div
							animate={{ rotate: isOpen ? 180 : 0 }}
							transition={{ duration: 0.2 }}
							className="group-data-[collapsible=icon]:hidden">
							<ChevronDown className="ml-auto h-4 w-4 shrink-0 opacity-50" />
						</motion.div>
					</Button>
				</PopoverTrigger>
				<PopoverContent className="w-56 p-2" align="start">
					<AnimatePresence>
						<motion.div
							initial={{ opacity: 0, y: -5 }}
							animate={{ opacity: 1, y: 0 }}
							exit={{ opacity: 0, y: -5 }}
							transition={{ duration: 0.2 }}>
							<UserMenuItem icon={User}>Profile</UserMenuItem>
							<UserMenuItem href="/settings/api-keys" icon={Settings}>
								API Keys
							</UserMenuItem>
							<UserMenuItem
								icon={LogOut}
								onClick={handleSignOut}
								className="text-red-600 hover:bg-red-100 hover:text-red-600">
								Log out
							</UserMenuItem>
						</motion.div>
					</AnimatePresence>
				</PopoverContent>
			</Popover>
		</div>
	);
}
