import { CampaignProps } from "@/types/campaign";
import { CampaignStatusEnum } from "@/app/enums/campaign.enum";

export const emptyNewCampaign: CampaignProps = {
	id: "",
	title: "",
	status: CampaignStatusEnum.Draft,
	activeStepId: "",
	sequence: [
		{
			id: "seq-" + new Date().getTime(),
			order: 0,
			subject: "",
			html: "",
			delayInDays: 3,
		},
	],
	client_id: "",
	workspace_id: "",
	created_by_user_id: "",
	date_created: "",
	date_updated: "",
	custom_field_requirements: {},
	campaignMetrics: {
		sent: 0,
		clicked: 0,
		clickedPct: "0%",
		opened: 0,
		openedPct: "0%",
		replied: 0,
		repliedPct: "0%",
		positiveReplies: 0,
		positiveRepliesPct: "0%",
	},
	hasLeads: 0,
	leads: [],
};
