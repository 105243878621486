import { ThreadOfEmails } from "@/types/inbox";
import { create } from "zustand";
import { persist } from "zustand/middleware";

type InboxStoreProps = {
	allThreads: Record<string, ThreadOfEmails>;
	threadsLoading: boolean;
	primary: ThreadOfEmails[];
	sent: ThreadOfEmails[];
	done: ThreadOfEmails[];
	setPrimary: (value: ThreadOfEmails[]) => void;
	setSent: (value: ThreadOfEmails[]) => void;
	setDone: (value: ThreadOfEmails[]) => void;
	selectedCampaignId: string | null;
	setSelectedCampaignId: (id: string | null) => void;
	updatePrimary: (emails: ThreadOfEmails[]) => void;
	updateThreadsWithData: (data: ThreadOfEmails[]) => void;
	syncWithLatest: (fetcher: () => Promise<ThreadOfEmails[]>) => Promise<void>;
};

const useInboxStore = create<InboxStoreProps>()(
	persist(
		(set, get) => ({
			allThreads: {},
			threadsLoading: false,
			primary: [],
			sent: [],
			done: [],
			selectedCampaignId: null,
			setPrimary: value => set({ primary: value }),
			setSent: value => set({ sent: value }),
			setDone: value => set({ done: value }),
			setSelectedCampaignId: id => set({ selectedCampaignId: id }),
			updatePrimary: emails =>
				set(state => ({
					...state,
					primary: emails,
				})),
			updateThreadsWithData: (data: ThreadOfEmails[]) => {
				if (!data.length) return;
				set({ threadsLoading: true });
				set(state => {
					const newAllThreads = {
						...state.allThreads,
						...data.reduce(
							(acc, thread) => {
								acc[thread.id] = thread;
								return acc;
							},
							{} as Record<string, ThreadOfEmails>,
						),
					};
					const result = {
						...state,
						allThreads: newAllThreads,
						primary: Object.values(newAllThreads)
							.filter(thread => thread.campaign && thread.status === "active")
							.toSorted((a, b) => new Date(b.last_message_at).getTime() - new Date(a.last_message_at).getTime()),
						done: Object.values(newAllThreads)
							.filter(thread => !thread.campaign && thread.status === "archived")
							.toSorted((a, b) => new Date(b.last_message_at).getTime() - new Date(a.last_message_at).getTime()),
					};
					return result;
				});
				set({ threadsLoading: false });
			},
			syncWithLatest: async (fetcher: () => Promise<ThreadOfEmails[]>) => {
				set({ threadsLoading: true });
				const result = await fetcher();
				get().updateThreadsWithData(result);
				set({ threadsLoading: false });
			},
		}),
		{
			name: "inbox-storage",
		},
	),
);

export default useInboxStore;
