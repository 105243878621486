import axios from "axios";
const baseURL = import.meta.env.VITE_API_URL || "http://localhost:3004";

export const ZazuAPI = axios.create({
	headers: {
		"Content-Type": "application/json",
	},
	withCredentials: true,
	baseURL,
});
