import { useSidebar } from "@/components/ui/sidebar";
import { Tooltip, TooltipTrigger, TooltipContent } from "@/components/ui/tooltip";
import IconSVG from "@/components/IconSVG/IconSVG";
import { sidebarTogglePath } from "@/assets/icons/paths";
import SelectEmailAccounts from "@/components/SelectEmailAccounts/SelectEmailAccounts";

const EmailAccounts = () => {
	const { toggleSidebar, state } = useSidebar();

	const renderHeader = () => (
		<header>
			<div className="flex h-[52px] items-center justify-between">
				<div className="flex items-center gap-2">
					<Tooltip delayDuration={500}>
						<TooltipTrigger asChild>
							<button
								onClick={toggleSidebar}
								className="flex h-6 w-6 items-center justify-center rounded-sm hover:bg-gray-100"
								aria-label="Toggle sidebar">
								<IconSVG path={sidebarTogglePath} width={16} height={16} viewBox="0 0 16 16" fill="#666666" />
							</button>
						</TooltipTrigger>
						<TooltipContent side="right">
							{state === "expanded" ? "Collapse the sidebar (⌘B)" : "Expand the sidebar (⌘B)"}
						</TooltipContent>
					</Tooltip>
					<h1 className="text-[15px] font-medium leading-[22px] text-[--label-label-title]">Email Accounts</h1>
				</div>
			</div>
		</header>
	);

	return (
		<div className="px-4">
			{renderHeader()}
			<div className="-mx-4 h-[0.5px] bg-[--background-bg-border]" />
			<SelectEmailAccounts shouldFetchMemberships={false} />
		</div>
	);
};

export default EmailAccounts;
