import { CampaignStatusEnum } from "@/app/enums/campaign.enum";
import { Leads } from "@za-zu/types";

export interface CampaignProps {
	id: string;
	title: string;
	status: CampaignStatusEnum;
	activeStepId: string;
	sequence: Array<{
		id: string;
		order: number;
		subject: string;
		html: string;
		delayInDays: number;
	}>;
	client_id: string;
	workspace_id: string;
	created_by_user_id: string;
	date_created: string;
	date_updated: string;
	custom_field_requirements: Record<string, boolean>;
	campaignMetrics: {
		sent: number;
		clicked: number;
		clickedPct: string;
		opened: number;
		openedPct: string;
		replied: number;
		repliedPct: string;
		positiveReplies: number;
		positiveRepliesPct: string;
	};
	hasLeads: number;
	leads: Leads.Lead[];
	leadsAreSet?: boolean;
	sortOrder?: number;
}

export interface CampaignStep {
	name: string | null;
	subject: string | null;
	html: string | null;
	delay_hours: number | null;
}

export const getDefaultMetrics = () => ({
	sent: 0,
	clicked: 0,
	clickedPct: "0%",
	opened: 0,
	openedPct: "0%",
	replied: 0,
	repliedPct: "0%",
	positiveReplies: 0,
	positiveRepliesPct: "0%",
});
